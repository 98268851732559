import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import PlayerPositionChart from './PlayerPositionChart';
import { PositionMapPoint } from './interfaces';

interface PositionMapElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

interface PositionMapData {
  'avg-positions-home': PositionMapPoint[];
  'avg-positions-away': PositionMapPoint[];
}

const PositionMapElement = memo(function PositionMapElement({ element, report }: PositionMapElementProps) {
  const data = element.entity_data as PositionMapData;

  return (
    <div className="flex flex-col gap-6">
      <PlayerPositionChart
        away={data['avg-positions-away'] ?? []}
        home={data['avg-positions-home'] ?? []}
        report={report}
      />
    </div>
  );
});

export default PositionMapElement;
