import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo, useMemo } from 'react';
import { ComparisonElementFormValues } from '../../dialogs/elementForms/interfaces';
import { AttributeValues } from '../interfaces';
import GenericMetricComparison from './GenericMetricComparison';
import { ComparisonElementPlayerResult, ComparisonElementResult, ComparisonElementTeamResult } from './interfaces';
import MultipleMetricTeamComparison from './MultipleMetricTeamComparison';
import SinglePlayerComparison from './SinglePlayerComparison';

interface ComparisonElementValues {
  result: ComparisonElementResult[];
}

interface ComparisonElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const ComparisonElement = memo(function ComparisonElement({ element, report }: ComparisonElementProps) {
  const attributeValues = element.attribute_values as AttributeValues<ComparisonElementFormValues>;
  const { result: results } = element.entity_data as ComparisonElementValues;

  return useMemo(() => {
    const isPlayer = attributeValues.attribute_type[0] === 'player';
    const isSingleMetric = isPlayer
      ? attributeValues.metrics_players.length === 1
      : attributeValues.metrics_teams.length === 1;
    if (!isPlayer && !isSingleMetric) {
      return <MultipleMetricTeamComparison results={results as ComparisonElementTeamResult[]} report={report} />;
    } else if (isPlayer && attributeValues?.players?.length === 1) {
      return <SinglePlayerComparison results={results as ComparisonElementPlayerResult[]} report={report} />;
    } else {
      return <GenericMetricComparison isPlayer={isPlayer} results={results} report={report} />;
    }
  }, [attributeValues, report, results]);
});

export default ComparisonElement;
