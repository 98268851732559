import { AxiosError } from 'axios';
import useIsMobile from 'hooks/useIsMobile';
import { useObjectsOptions } from 'hooks/useOptions';
import { useGetProjectOrganizationType } from 'lib/organization-type/organization-type';
import { useGetUserClub, useGetUserCountry, usePostUserRegister } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import Combobox from 'modules/common/Form/Combobox';
import Input from 'modules/common/Form/Input';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import ImageUpload from 'modules/common/ImageUpload';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading/dist/typings';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { RegisterFormValues, ValueOption } from 'utils/interfaces';

interface CreateOrganizationFormValues {
  organization: ValueOption;
  organizationType: ValueOption;
  organizationImage?: string;
  name?: string;
  country?: ValueOption;
  city?: ValueOption;
  zipCode?: string;
}

interface CreateOrganizationFormProps {
  userInfo: RegisterFormValues;
}

const defaultOrgValues = {
  organization: undefined,
  organizationType: undefined
};

const CreateOrganizationForm = memo(function CreateOrganizationForm({ userInfo }: CreateOrganizationFormProps) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [images, setImages] = useState([] as ImageListType);
  const { handleSubmit, control, register, watch, setValue, resetField } = useForm<CreateOrganizationFormValues>({
    defaultValues: defaultOrgValues
  });

  const handleImages = (images: ImageListType) => {
    setImages(images);
    const image = images[0];
    if (image) {
      setValue('organizationImage', image.dataURL);
    } else {
      resetField('organizationImage');
    }
  };

  const selectedOrg = watch('organization');

  const { data: organizationTypes } = useGetProjectOrganizationType({
    query: { queryKey: ['organizationTypes'] }
  });
  const { data: organizations, isPending: isOrganizationsPending } = useGetUserClub({
    query: { queryKey: ['organizations'] }
  });
  const organizationTypeOptions = useObjectsOptions(organizationTypes);
  const organizationOptions = useObjectsOptions(organizations, {
    defaultOptions: [{ label: 'Not listed', id: '' }]
  });
  const [cities, setCities] = useState<ValueOption[]>([]);
  const { data: countries } = useGetUserCountry({ query: { queryKey: ['countries'] } });
  const countryOptions = countries?.objects!.map((x) => ({ id: x.iso3!, label: x.country!, value: x! }) as ValueOption);

  const { mutate: postRegister, isPending } = usePostUserRegister();

  function onSubmit(data: CreateOrganizationFormValues) {
    postRegister(
      {
        data: {
          email: userInfo.email,
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
          password: userInfo.password,
          organization:
            data.organization.label !== 'Not listed'
              ? {
                  organization_name: data.organization.label,
                  organization_type_id: data.organizationType.id as string,
                  organization_image: data.organizationImage
                }
              : {
                  organization_name: data.name!,
                  organization_type_id: data.organizationType.id as string,
                  organization_image: data.organizationImage,
                  city: data.city?.label,
                  country: data.country?.label,
                  zip_code: data.zipCode
                }
        }
      },
      {
        onSuccess: () => {
          if (data.organization.label === 'Not listed') {
            navigate('/unknown-organization');
          } else {
            navigate('/email-mismatch');
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.status === 500) {
              toast.error('An unknown error occured. Please try again.');
            }
            const errors = err.response?.data;
            if (errors.email) {
              toast.error('Email already taken. Please choose a different one.');
            }
          }
        }
      }
    );
  }

  useEffect(() => {
    const subscription = watch(({ country }, { name }) => {
      if (name === 'country') {
        resetField('city');
        // setCities((country?.value as Country).cities!.map((x) => ({ id: country?.id + '-' + x, label: x })));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField]);

  return (
    <div
      className={twMerge(
        'flex w-full max-w-xl flex-col gap-10 rounded-3xl bg-white p-16',
        isMobile && 'rounded-none p-8'
      )}
    >
      <h2 className="mb-2 text-h6">Create your Q-ant Data Environment</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10">
        <div className="flex flex-col gap-6">
          <span className="text-md font-semibold">Organization information</span>
          <SingleSelectInput
            control={control}
            name="organizationType"
            label="Organization Type"
            options={organizationTypeOptions}
          />
          <Combobox
            loading={isOrganizationsPending}
            control={control}
            name="organization"
            label="Organization"
            options={organizationOptions}
          />
          <div className={twMerge('hidden flex-col gap-6', (selectedOrg?.id as string)?.length === 0 && 'flex')}>
            <Input registerReturn={register('name')} label="Enter organization name" />
            <SingleSelectInput control={control} name="country" label="County" options={countryOptions} />
            <div className="flex justify-between gap-6">
              <Input registerReturn={register('city.label')} label="City" />
              {/* TEMP: Loading all cities for countries with many cities causes browser to crash */}
              {/* <SingleSelectInput control={control} name="city" label="City" options={cities} /> */}
              <Input registerReturn={register('zipCode')} label="Zip Code" />
            </div>
          </div>
          <ImageUpload images={images} setImages={handleImages} optional />
        </div>
        <Button isSubmitButton={true} size="xl" loading={isPending}>
          Create Q-ant Data Environment
        </Button>
      </form>
    </div>
  );
});

export default CreateOrganizationForm;
