/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as getUserClubMutator,
  default as getUserConfirmEmailMutator,
  default as getUserCountryMutator,
  default as getUserMutator,
  default as getUserSupportCategoryMutator,
  default as getUserUserIdMutator,
  default as postUserChangePasswordMutator,
  default as postUserContactMutator,
  default as postUserLoginMutator,
  default as postUserLogoutMutator,
  default as postUserRegisterMutator,
  default as postUserSupportTicketMutator,
  default as putUserDeactivateMutator,
  default as putUserDeactivateUsersMutator,
  default as putUserMutator,
  default as putUserRequestResetPasswordMutator,
  default as putUserResetPasswordMutator
} from '../../api/axios';
import type {
  ClubsSchema,
  ContactSchema,
  CountriesSchema,
  GetUserConfirmEmailParams,
  PostUserRegisterParams,
  PutUserDeactivateUsersParams,
  PutUserResetPasswordParams,
  SupportCategoriesSchema,
  SupportTicketCreateSchema,
  UserAccountChangePasswordSchema,
  UserAccountDeactivateSchema,
  UserAccountGetSchema,
  UserAccountLoginResponse,
  UserAccountLoginSchema,
  UserAccountRegisterSchema,
  UserAccountRequestResetPasswordSchema,
  UserAccountResetPasswordSchema,
  UserAccountSchema,
  UserAccountUpdateSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns the current users' account data
 */
export const getUser = (options?: SecondParameter<typeof getUserMutator>, signal?: AbortSignal) => {
  return getUserMutator<UserAccountGetSchema>({ url: `/user`, method: 'GET', signal }, options);
};

export const getGetUserQueryKey = () => {
  return [`/user`] as const;
};

export const getGetUserQueryOptions = <TData = Awaited<ReturnType<typeof getUser>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>;
  request?: SecondParameter<typeof getUserMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>;
export type GetUserQueryError = unknown;

/**
 * @summary Returns the current users' account data
 */
export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>;
  request?: SecondParameter<typeof getUserMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Updates the current user account
 */
export const putUser = (
  userAccountUpdateSchema: UserAccountUpdateSchema,
  options?: SecondParameter<typeof putUserMutator>
) => {
  return putUserMutator<UserAccountSchema>(
    { url: `/user`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: userAccountUpdateSchema },
    options
  );
};

export const getPutUserMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUser>>,
    TError,
    { data: UserAccountUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putUserMutator>;
}): UseMutationOptions<Awaited<ReturnType<typeof putUser>>, TError, { data: UserAccountUpdateSchema }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUser>>, { data: UserAccountUpdateSchema }> = (
    props
  ) => {
    const { data } = props ?? {};

    return putUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserMutationResult = NonNullable<Awaited<ReturnType<typeof putUser>>>;
export type PutUserMutationBody = UserAccountUpdateSchema;
export type PutUserMutationError = unknown;

/**
 * @summary Updates the current user account
 */
export const usePutUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUser>>,
    TError,
    { data: UserAccountUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putUserMutator>;
}): UseMutationResult<Awaited<ReturnType<typeof putUser>>, TError, { data: UserAccountUpdateSchema }, TContext> => {
  const mutationOptions = getPutUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Change the current users password
 */
export const postUserChangePassword = (
  userAccountChangePasswordSchema: UserAccountChangePasswordSchema,
  options?: SecondParameter<typeof postUserChangePasswordMutator>
) => {
  return postUserChangePasswordMutator<void>(
    {
      url: `/user/change-password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userAccountChangePasswordSchema
    },
    options
  );
};

export const getPostUserChangePasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserChangePassword>>,
    TError,
    { data: UserAccountChangePasswordSchema },
    TContext
  >;
  request?: SecondParameter<typeof postUserChangePasswordMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserChangePassword>>,
  TError,
  { data: UserAccountChangePasswordSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserChangePassword>>,
    { data: UserAccountChangePasswordSchema }
  > = (props) => {
    const { data } = props ?? {};

    return postUserChangePassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postUserChangePassword>>>;
export type PostUserChangePasswordMutationBody = UserAccountChangePasswordSchema;
export type PostUserChangePasswordMutationError = unknown;

/**
 * @summary Change the current users password
 */
export const usePostUserChangePassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserChangePassword>>,
    TError,
    { data: UserAccountChangePasswordSchema },
    TContext
  >;
  request?: SecondParameter<typeof postUserChangePasswordMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserChangePassword>>,
  TError,
  { data: UserAccountChangePasswordSchema },
  TContext
> => {
  const mutationOptions = getPostUserChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a list of clubs the user can choose from to set as his club
 */
export const getUserClub = (options?: SecondParameter<typeof getUserClubMutator>, signal?: AbortSignal) => {
  return getUserClubMutator<ClubsSchema>({ url: `/user/club`, method: 'GET', signal }, options);
};

export const getGetUserClubQueryKey = () => {
  return [`/user/club`] as const;
};

export const getGetUserClubQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserClub>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserClub>>, TError, TData>>;
  request?: SecondParameter<typeof getUserClubMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserClubQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserClub>>> = ({ signal }) =>
    getUserClub(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserClub>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserClubQueryResult = NonNullable<Awaited<ReturnType<typeof getUserClub>>>;
export type GetUserClubQueryError = unknown;

/**
 * @summary Get a list of clubs the user can choose from to set as his club
 */
export const useGetUserClub = <TData = Awaited<ReturnType<typeof getUserClub>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserClub>>, TError, TData>>;
  request?: SecondParameter<typeof getUserClubMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserClubQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Confirm a newly registered users email
 */
export const getUserConfirmEmail = (
  params?: GetUserConfirmEmailParams,
  options?: SecondParameter<typeof getUserConfirmEmailMutator>,
  signal?: AbortSignal
) => {
  return getUserConfirmEmailMutator<void>({ url: `/user/confirm-email`, method: 'GET', params, signal }, options);
};

export const getGetUserConfirmEmailQueryKey = (params?: GetUserConfirmEmailParams) => {
  return [`/user/confirm-email`, ...(params ? [params] : [])] as const;
};

export const getGetUserConfirmEmailQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserConfirmEmail>>,
  TError = unknown
>(
  params?: GetUserConfirmEmailParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserConfirmEmail>>, TError, TData>>;
    request?: SecondParameter<typeof getUserConfirmEmailMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserConfirmEmailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserConfirmEmail>>> = ({ signal }) =>
    getUserConfirmEmail(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserConfirmEmail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserConfirmEmailQueryResult = NonNullable<Awaited<ReturnType<typeof getUserConfirmEmail>>>;
export type GetUserConfirmEmailQueryError = unknown;

/**
 * @summary Confirm a newly registered users email
 */
export const useGetUserConfirmEmail = <TData = Awaited<ReturnType<typeof getUserConfirmEmail>>, TError = unknown>(
  params?: GetUserConfirmEmailParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserConfirmEmail>>, TError, TData>>;
    request?: SecondParameter<typeof getUserConfirmEmailMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserConfirmEmailQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Sends a contact message
 */
export const postUserContact = (
  contactSchema: ContactSchema,
  options?: SecondParameter<typeof postUserContactMutator>
) => {
  return postUserContactMutator<void>(
    { url: `/user/contact`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: contactSchema },
    options
  );
};

export const getPostUserContactMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postUserContact>>, TError, { data: ContactSchema }, TContext>;
  request?: SecondParameter<typeof postUserContactMutator>;
}): UseMutationOptions<Awaited<ReturnType<typeof postUserContact>>, TError, { data: ContactSchema }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserContact>>, { data: ContactSchema }> = (
    props
  ) => {
    const { data } = props ?? {};

    return postUserContact(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserContactMutationResult = NonNullable<Awaited<ReturnType<typeof postUserContact>>>;
export type PostUserContactMutationBody = ContactSchema;
export type PostUserContactMutationError = unknown;

/**
 * @summary Sends a contact message
 */
export const usePostUserContact = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postUserContact>>, TError, { data: ContactSchema }, TContext>;
  request?: SecondParameter<typeof postUserContactMutator>;
}): UseMutationResult<Awaited<ReturnType<typeof postUserContact>>, TError, { data: ContactSchema }, TContext> => {
  const mutationOptions = getPostUserContactMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a list of countries the user can choose from to set as his country
 */
export const getUserCountry = (options?: SecondParameter<typeof getUserCountryMutator>, signal?: AbortSignal) => {
  return getUserCountryMutator<CountriesSchema>({ url: `/user/country`, method: 'GET', signal }, options);
};

export const getGetUserCountryQueryKey = () => {
  return [`/user/country`] as const;
};

export const getGetUserCountryQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserCountry>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserCountry>>, TError, TData>>;
  request?: SecondParameter<typeof getUserCountryMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserCountryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCountry>>> = ({ signal }) =>
    getUserCountry(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserCountry>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserCountryQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCountry>>>;
export type GetUserCountryQueryError = unknown;

/**
 * @summary Get a list of countries the user can choose from to set as his country
 */
export const useGetUserCountry = <TData = Awaited<ReturnType<typeof getUserCountry>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserCountry>>, TError, TData>>;
  request?: SecondParameter<typeof getUserCountryMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserCountryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Deactivate current user account (kept in database but as deleted)
 */
export const putUserDeactivate = (
  userAccountDeactivateSchema: UserAccountDeactivateSchema,
  options?: SecondParameter<typeof putUserDeactivateMutator>
) => {
  return putUserDeactivateMutator<void>(
    {
      url: `/user/deactivate`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: userAccountDeactivateSchema
    },
    options
  );
};

export const getPutUserDeactivateMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserDeactivate>>,
    TError,
    { data: UserAccountDeactivateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putUserDeactivateMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserDeactivate>>,
  TError,
  { data: UserAccountDeactivateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserDeactivate>>,
    { data: UserAccountDeactivateSchema }
  > = (props) => {
    const { data } = props ?? {};

    return putUserDeactivate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserDeactivateMutationResult = NonNullable<Awaited<ReturnType<typeof putUserDeactivate>>>;
export type PutUserDeactivateMutationBody = UserAccountDeactivateSchema;
export type PutUserDeactivateMutationError = unknown;

/**
 * @summary Deactivate current user account (kept in database but as deleted)
 */
export const usePutUserDeactivate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserDeactivate>>,
    TError,
    { data: UserAccountDeactivateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putUserDeactivateMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserDeactivate>>,
  TError,
  { data: UserAccountDeactivateSchema },
  TContext
> => {
  const mutationOptions = getPutUserDeactivateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deactivate users accounts (kept in database but like deleted)
 */
export const putUserDeactivateUsers = (
  params?: PutUserDeactivateUsersParams,
  options?: SecondParameter<typeof putUserDeactivateUsersMutator>
) => {
  return putUserDeactivateUsersMutator<void>({ url: `/user/deactivate-users`, method: 'PUT', params }, options);
};

export const getPutUserDeactivateUsersMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserDeactivateUsers>>,
    TError,
    { params?: PutUserDeactivateUsersParams },
    TContext
  >;
  request?: SecondParameter<typeof putUserDeactivateUsersMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserDeactivateUsers>>,
  TError,
  { params?: PutUserDeactivateUsersParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserDeactivateUsers>>,
    { params?: PutUserDeactivateUsersParams }
  > = (props) => {
    const { params } = props ?? {};

    return putUserDeactivateUsers(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserDeactivateUsersMutationResult = NonNullable<Awaited<ReturnType<typeof putUserDeactivateUsers>>>;

export type PutUserDeactivateUsersMutationError = unknown;

/**
 * @summary Deactivate users accounts (kept in database but like deleted)
 */
export const usePutUserDeactivateUsers = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserDeactivateUsers>>,
    TError,
    { params?: PutUserDeactivateUsersParams },
    TContext
  >;
  request?: SecondParameter<typeof putUserDeactivateUsersMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserDeactivateUsers>>,
  TError,
  { params?: PutUserDeactivateUsersParams },
  TContext
> => {
  const mutationOptions = getPutUserDeactivateUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Log in to an existing account
 */
export const postUserLogin = (
  userAccountLoginSchema: UserAccountLoginSchema,
  options?: SecondParameter<typeof postUserLoginMutator>
) => {
  return postUserLoginMutator<UserAccountLoginResponse>(
    {
      url: `/user/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userAccountLoginSchema
    },
    options
  );
};

export const getPostUserLoginMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserLogin>>,
    TError,
    { data: UserAccountLoginSchema },
    TContext
  >;
  request?: SecondParameter<typeof postUserLoginMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserLogin>>,
  TError,
  { data: UserAccountLoginSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserLogin>>, { data: UserAccountLoginSchema }> = (
    props
  ) => {
    const { data } = props ?? {};

    return postUserLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postUserLogin>>>;
export type PostUserLoginMutationBody = UserAccountLoginSchema;
export type PostUserLoginMutationError = unknown;

/**
 * @summary Log in to an existing account
 */
export const usePostUserLogin = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserLogin>>,
    TError,
    { data: UserAccountLoginSchema },
    TContext
  >;
  request?: SecondParameter<typeof postUserLoginMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserLogin>>,
  TError,
  { data: UserAccountLoginSchema },
  TContext
> => {
  const mutationOptions = getPostUserLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Log out the currently logged in user
 */
export const postUserLogout = (options?: SecondParameter<typeof postUserLogoutMutator>) => {
  return postUserLogoutMutator<void>({ url: `/user/logout`, method: 'POST' }, options);
};

export const getPostUserLogoutMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postUserLogout>>, TError, void, TContext>;
  request?: SecondParameter<typeof postUserLogoutMutator>;
}): UseMutationOptions<Awaited<ReturnType<typeof postUserLogout>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserLogout>>, void> = () => {
    return postUserLogout(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof postUserLogout>>>;

export type PostUserLogoutMutationError = unknown;

/**
 * @summary Log out the currently logged in user
 */
export const usePostUserLogout = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postUserLogout>>, TError, void, TContext>;
  request?: SecondParameter<typeof postUserLogoutMutator>;
}): UseMutationResult<Awaited<ReturnType<typeof postUserLogout>>, TError, void, TContext> => {
  const mutationOptions = getPostUserLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Register and create a new user
 */
export const postUserRegister = (
  userAccountRegisterSchema: UserAccountRegisterSchema,
  params?: PostUserRegisterParams,
  options?: SecondParameter<typeof postUserRegisterMutator>
) => {
  return postUserRegisterMutator<void>(
    {
      url: `/user/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userAccountRegisterSchema,
      params
    },
    options
  );
};

export const getPostUserRegisterMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserRegister>>,
    TError,
    { data: UserAccountRegisterSchema; params?: PostUserRegisterParams },
    TContext
  >;
  request?: SecondParameter<typeof postUserRegisterMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserRegister>>,
  TError,
  { data: UserAccountRegisterSchema; params?: PostUserRegisterParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserRegister>>,
    { data: UserAccountRegisterSchema; params?: PostUserRegisterParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return postUserRegister(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserRegisterMutationResult = NonNullable<Awaited<ReturnType<typeof postUserRegister>>>;
export type PostUserRegisterMutationBody = UserAccountRegisterSchema;
export type PostUserRegisterMutationError = unknown;

/**
 * @summary Register and create a new user
 */
export const usePostUserRegister = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserRegister>>,
    TError,
    { data: UserAccountRegisterSchema; params?: PostUserRegisterParams },
    TContext
  >;
  request?: SecondParameter<typeof postUserRegisterMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserRegister>>,
  TError,
  { data: UserAccountRegisterSchema; params?: PostUserRegisterParams },
  TContext
> => {
  const mutationOptions = getPostUserRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Request a password reset to be sent to the provided email
 */
export const putUserRequestResetPassword = (
  userAccountRequestResetPasswordSchema: UserAccountRequestResetPasswordSchema,
  options?: SecondParameter<typeof putUserRequestResetPasswordMutator>
) => {
  return putUserRequestResetPasswordMutator<void>(
    {
      url: `/user/request-reset-password`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: userAccountRequestResetPasswordSchema
    },
    options
  );
};

export const getPutUserRequestResetPasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserRequestResetPassword>>,
    TError,
    { data: UserAccountRequestResetPasswordSchema },
    TContext
  >;
  request?: SecondParameter<typeof putUserRequestResetPasswordMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserRequestResetPassword>>,
  TError,
  { data: UserAccountRequestResetPasswordSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserRequestResetPassword>>,
    { data: UserAccountRequestResetPasswordSchema }
  > = (props) => {
    const { data } = props ?? {};

    return putUserRequestResetPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserRequestResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUserRequestResetPassword>>
>;
export type PutUserRequestResetPasswordMutationBody = UserAccountRequestResetPasswordSchema;
export type PutUserRequestResetPasswordMutationError = unknown;

/**
 * @summary Request a password reset to be sent to the provided email
 */
export const usePutUserRequestResetPassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserRequestResetPassword>>,
    TError,
    { data: UserAccountRequestResetPasswordSchema },
    TContext
  >;
  request?: SecondParameter<typeof putUserRequestResetPasswordMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserRequestResetPassword>>,
  TError,
  { data: UserAccountRequestResetPasswordSchema },
  TContext
> => {
  const mutationOptions = getPutUserRequestResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Reset a users password
 */
export const putUserResetPassword = (
  userAccountResetPasswordSchema: UserAccountResetPasswordSchema,
  params?: PutUserResetPasswordParams,
  options?: SecondParameter<typeof putUserResetPasswordMutator>
) => {
  return putUserResetPasswordMutator<void>(
    {
      url: `/user/reset-password`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: userAccountResetPasswordSchema,
      params
    },
    options
  );
};

export const getPutUserResetPasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserResetPassword>>,
    TError,
    { data: UserAccountResetPasswordSchema; params?: PutUserResetPasswordParams },
    TContext
  >;
  request?: SecondParameter<typeof putUserResetPasswordMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUserResetPassword>>,
  TError,
  { data: UserAccountResetPasswordSchema; params?: PutUserResetPasswordParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUserResetPassword>>,
    { data: UserAccountResetPasswordSchema; params?: PutUserResetPasswordParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return putUserResetPassword(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUserResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof putUserResetPassword>>>;
export type PutUserResetPasswordMutationBody = UserAccountResetPasswordSchema;
export type PutUserResetPasswordMutationError = unknown;

/**
 * @summary Reset a users password
 */
export const usePutUserResetPassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUserResetPassword>>,
    TError,
    { data: UserAccountResetPasswordSchema; params?: PutUserResetPasswordParams },
    TContext
  >;
  request?: SecondParameter<typeof putUserResetPasswordMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUserResetPassword>>,
  TError,
  { data: UserAccountResetPasswordSchema; params?: PutUserResetPasswordParams },
  TContext
> => {
  const mutationOptions = getPutUserResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a list of support categories
 */
export const getUserSupportCategory = (
  options?: SecondParameter<typeof getUserSupportCategoryMutator>,
  signal?: AbortSignal
) => {
  return getUserSupportCategoryMutator<SupportCategoriesSchema>(
    { url: `/user/support-category`, method: 'GET', signal },
    options
  );
};

export const getGetUserSupportCategoryQueryKey = () => {
  return [`/user/support-category`] as const;
};

export const getGetUserSupportCategoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSupportCategory>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserSupportCategory>>, TError, TData>>;
  request?: SecondParameter<typeof getUserSupportCategoryMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSupportCategoryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSupportCategory>>> = ({ signal }) =>
    getUserSupportCategory(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSupportCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSupportCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getUserSupportCategory>>>;
export type GetUserSupportCategoryQueryError = unknown;

/**
 * @summary Get a list of support categories
 */
export const useGetUserSupportCategory = <
  TData = Awaited<ReturnType<typeof getUserSupportCategory>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserSupportCategory>>, TError, TData>>;
  request?: SecondParameter<typeof getUserSupportCategoryMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserSupportCategoryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a new support ticket
 */
export const postUserSupportTicket = (
  supportTicketCreateSchema: SupportTicketCreateSchema,
  options?: SecondParameter<typeof postUserSupportTicketMutator>
) => {
  return postUserSupportTicketMutator<void>(
    {
      url: `/user/support-ticket`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: supportTicketCreateSchema
    },
    options
  );
};

export const getPostUserSupportTicketMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSupportTicket>>,
    TError,
    { data: SupportTicketCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postUserSupportTicketMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUserSupportTicket>>,
  TError,
  { data: SupportTicketCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUserSupportTicket>>,
    { data: SupportTicketCreateSchema }
  > = (props) => {
    const { data } = props ?? {};

    return postUserSupportTicket(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserSupportTicketMutationResult = NonNullable<Awaited<ReturnType<typeof postUserSupportTicket>>>;
export type PostUserSupportTicketMutationBody = SupportTicketCreateSchema;
export type PostUserSupportTicketMutationError = unknown;

/**
 * @summary Create a new support ticket
 */
export const usePostUserSupportTicket = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUserSupportTicket>>,
    TError,
    { data: SupportTicketCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postUserSupportTicketMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUserSupportTicket>>,
  TError,
  { data: SupportTicketCreateSchema },
  TContext
> => {
  const mutationOptions = getPostUserSupportTicketMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns user account data
 */
export const getUserUserId = (
  userId: string,
  options?: SecondParameter<typeof getUserUserIdMutator>,
  signal?: AbortSignal
) => {
  return getUserUserIdMutator<UserAccountSchema>(
    { url: `/user/${encodeURIComponent(String(userId))}`, method: 'GET', signal },
    options
  );
};

export const getGetUserUserIdQueryKey = (userId: string) => {
  return [`/user/${userId}`] as const;
};

export const getGetUserUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserUserId>>, TError = unknown>(
  userId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserUserId>>, TError, TData>>;
    request?: SecondParameter<typeof getUserUserIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserUserIdQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserUserId>>> = ({ signal }) =>
    getUserUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserUserId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserUserId>>>;
export type GetUserUserIdQueryError = unknown;

/**
 * @summary Returns user account data
 */
export const useGetUserUserId = <TData = Awaited<ReturnType<typeof getUserUserId>>, TError = unknown>(
  userId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserUserId>>, TError, TData>>;
    request?: SecondParameter<typeof getUserUserIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserUserIdQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
