import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';
import { AttributeValues } from '../../elements/interfaces';

import { attributeValuesOption } from './helpers';
import { ElementFormProps, ScatterChartElementFormValues } from './interfaces';

const ScatterChartElementForm = memo(function ScatterChartElementForm({
  template,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<ScatterChartElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<ScatterChartElementFormValues>;
    if (values.x_axis) {
      defaultValues.x_axis = attributeValuesOption(values.x_axis as string[]);
    }
    if (values.y_axis) {
      defaultValues.y_axis = attributeValuesOption(values.y_axis as string[]);
    }
  }
  const { control, watch, formState, handleSubmit } = useForm<ScatterChartElementFormValues>({
    defaultValues
  });

  const xAxis = watch('x_axis');
  const yAxis = watch('y_axis');

  const xAxisFilteredOptions = useMemo(
    () =>
      template
        .attribute_choices!.x_axis.filter((option: string) => option !== yAxis?.id)
        .map(
          (option: string) =>
            ({
              label: snakeCaseToWords(option),
              id: option
            }) as ValueOption
        ),
    [template.attribute_choices, yAxis?.id]
  );
  const yAxisFilteredOptions = useMemo(
    () =>
      template
        .attribute_choices!.y_axis.filter((option: string) => option !== xAxis?.id)
        .map(
          (option: string) =>
            ({
              label: snakeCaseToWords(option),
              id: option
            }) as ValueOption
        ),
    [template.attribute_choices, xAxis?.id]
  );

  const addElement = useCallback(
    function addElement(data: ScatterChartElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <SingleSelectInput
        name={'x_axis'}
        control={control}
        label={'X Axis'}
        options={xAxisFilteredOptions}
        rules={{ required: 'X Axis is required!' }}
        error={formState.errors.x_axis}
      />
      <SingleSelectInput
        name={'y_axis'}
        control={control}
        label={'Y Axis'}
        options={yAxisFilteredOptions}
        rules={{ required: 'Y Axis is required!' }}
        error={formState.errors.x_axis}
      />
    </form>
  );
});

export default ScatterChartElementForm;
