import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import { ScatterChartElementFormValues } from '../../dialogs/elementForms/interfaces';
import { AttributeValues } from '../interfaces';
import ScatterChart from './ScatterChart';
import ScatterChartLegend from './ScatterChartLegend';
import { ScatterChartPoint } from './interfaces';

interface ScatterChartElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

interface ScatterChartData {
  result: ScatterChartPoint[];
}

const ScatterChartElement = memo(function ScatterChartElement({ report, element }: ScatterChartElementProps) {
  const attributes = element.attribute_values as AttributeValues<ScatterChartElementFormValues>;
  const { result: points } = element.entity_data as ScatterChartData;
  return (
    <div className="flex flex-col gap-6">
      <ScatterChartLegend report={report} />
      <ScatterChart
        points={points}
        xField={attributes.x_axis[0] as string}
        yField={attributes.y_axis[0] as string}
        report={report}
      />
    </div>
  );
});

export default ScatterChartElement;
