import { snakeCaseToWords } from '../../../../../../utils/helpers';
import { Competition, Match, MatchDetailed, Player, ValueOption } from '../../../../../../utils/interfaces';
import { EventMapEvent } from './interfaces';

export function attributeValuesPlayers(allPlayers: Player[], selectedPlayers: number[]): ValueOption[] {
  if (allPlayers.length === selectedPlayers.length) {
    return [];
  }
  return allPlayers
    .filter((player: Player) => selectedPlayers.includes(player.player_id))
    .map(
      (player: Player) =>
        ({
          label: player.player_name,
          id: player.player_id
        }) as ValueOption
    );
}

export function attributeValuesPlayersHeatMap(allPlayers: Player[], selectedPlayers: number[]): ValueOption[] {
  return allPlayers
    .filter((player: Player) => selectedPlayers.includes(player.player_id))
    .map(
      (player: Player) =>
        ({
          label: player.player_name,
          id: player.player_id
        }) as ValueOption
    );
}

export function attributeValuesCompetitions(
  allCompetitions: Competition[],
  selectedCompetitions: number[]
): ValueOption[] {
  return allCompetitions
    .filter((competition: Competition) => selectedCompetitions.includes(competition.competition_id))
    .map(
      (competition: Competition) =>
        ({
          label: competition.competition_name,
          id: competition.competition_id
        }) as ValueOption
    );
}

export function attributeValuesCompetition(
  allCompetitions: Competition[],
  selectedCompetitions: number[]
): ValueOption {
  return allCompetitions
    .filter((competition: Competition) => selectedCompetitions.includes(competition.competition_id))
    .map(
      (competition: Competition) =>
        ({
          label: competition.competition_name,
          id: competition.competition_id
        }) as ValueOption
    )
    .at(0)!;
}

export function attributeValuesMatchDetailed(allMatches: MatchDetailed[], selectedMatch: number[]): ValueOption {
  return allMatches
    .filter((match: MatchDetailed) => selectedMatch.includes(match.match_id))
    .map(
      (match: MatchDetailed) =>
        ({
          // Using == operator to check for nullish constraints while letting 0 through
          // eslint-disable-next-line eqeqeq
          label: `${match.home_team_name} - ${match.away_team_name}${!(match.home_team_score == null) ? ` (${match.home_team_score}:${match.away_team_score})` : ''}, ${match.competition_name}, ${match.season}, ${new Date(match.date).toLocaleDateString()}`,
          id: match.match_id
        }) as ValueOption
    )
    .at(0)!;
}

export function attributeValuesMatch(allMatches: Match[], selectedMatch: number[]): ValueOption {
  return allMatches
    .filter((match: Match) => selectedMatch.includes(match.match_id))
    .map(
      (match: Match) =>
        ({
          label: `${match.home_team_name} vs ${match.away_team_name} | ${new Date(match.date).toLocaleDateString()}`,
          id: match.match_id
        }) as ValueOption
    )
    .at(0)!;
}

export function attributeValuesOption(values: string[]): ValueOption {
  return values
    .map(
      (option: string) =>
        ({
          label: snakeCaseToWords(option),
          id: option
        }) as ValueOption
    )
    .at(0)!;
}

export function attributeValuesOptions(values: string[], all?: boolean, allValues?: string[]): ValueOption[] {
  if (all && allValues && allValues.length === values.length) {
    return [];
  }

  return values.map(
    (option: string) =>
      ({
        label: snakeCaseToWords(option),
        id: option
      }) as ValueOption
  );
}

export function attributeValuesEvents(allEvents: EventMapEvent[], selectedEvent: string[]): ValueOption | null {
  return (
    allEvents
      .filter((event) => selectedEvent.includes(JSON.stringify(event)))
      .map((event: EventMapEvent) => {
        if (event.event_type === 'Goal') {
          return {
            id: event.minutes_elapsed,
            label: `${event.minutes_elapsed}' | ${event.player_name} | ${event.team_name}`,
            value: event
          } satisfies ValueOption;
        }
        return {
          id: event.minutes_elapsed,
          label: event.event_type,
          value: event
        } satisfies ValueOption;
      })
      .at(0) ?? null
  );
}
