import usePrefetchScoutReportDropdowns from 'hooks/usePrefetchScoutReportDropdowns';
import DialogBase from 'modules/common/Dialog/DialogBase';
import { memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DialogProps } from 'utils/interfaces';
import { ScoutReportFormValues } from './interfaces';
import ScoutReportFilteredPlayersOptionsForm from './ScoutReportFilteredPlayersForm/ScoutReportFilteredPlayersOptionsForm';
import ScoutReportForm from './ScoutReportForm';
import ScoutReportManualPlayersOptionsForm from './ScoutReportManualPlayersForm/ScoutReportManualPlayersOptionsForm';
import ScoutReportType from './ScoutReportType';

enum ScoutReportStepEnum {
  TYPE,
  FILTERED_PLAYERS_OPTIONS,
  MANUAL_PLAYERS_OPTIONS,
  CREATE_REPORT
}

const NewScoutReportDialog = memo(function NewScoutReportDialog({ open, setOpen }: DialogProps) {
  const [step, setStep] = useState<ScoutReportStepEnum>(ScoutReportStepEnum.TYPE);
  const [scoutReportType, setScoutReportType] = useState<string | null>(null);
  usePrefetchScoutReportDropdowns();

  const scoutReportForm = useForm<ScoutReportFormValues>({
    defaultValues: {
      numberOfFilteredPlayers: 0,
      reportName: null,
      minPlayedGames: null,
      removedPlayers: [],
      players: []
    }
  });

  const handleBack = useCallback(
    function handleBack() {
      if (step === ScoutReportStepEnum.CREATE_REPORT) {
        if (scoutReportType === 'manual') {
          setStep(ScoutReportStepEnum.MANUAL_PLAYERS_OPTIONS);
        } else if (scoutReportType === 'filter') {
          setStep(ScoutReportStepEnum.FILTERED_PLAYERS_OPTIONS);
        }
      } else {
        setStep(ScoutReportStepEnum.TYPE);
      }
    },
    [scoutReportType, step]
  );

  const handleNext = useCallback(
    function handleNext() {
      if (step === ScoutReportStepEnum.TYPE) {
        if (scoutReportType === 'manual') {
          return setStep(ScoutReportStepEnum.MANUAL_PLAYERS_OPTIONS);
        } else if (scoutReportType === 'filter') {
          return setStep(ScoutReportStepEnum.FILTERED_PLAYERS_OPTIONS);
        }
      } else {
        setStep(ScoutReportStepEnum.CREATE_REPORT);
      }
    },
    [step, scoutReportType]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  useEffect(() => {
    if (scoutReportType === 'manual') {
      scoutReportForm.setValue('numberOfFilteredPlayers', 0);
    } else if (scoutReportType === 'filter') {
      scoutReportForm.setValue('numberOfFilteredPlayers', 10);
    }
  });

  return (
    <DialogBase title="New Scout Report" onCancel={handleCancel} open={open}>
      {step === ScoutReportStepEnum.TYPE && (
        <ScoutReportType
          scoutReportType={scoutReportType}
          setScoutReportType={setScoutReportType}
          onBack={handleCancel}
          onNext={handleNext}
        />
      )}
      {step === ScoutReportStepEnum.FILTERED_PLAYERS_OPTIONS && (
        <ScoutReportFilteredPlayersOptionsForm
          scoutReportForm={scoutReportForm}
          onBack={handleBack}
          onNext={handleNext}
        />
      )}
      {step === ScoutReportStepEnum.MANUAL_PLAYERS_OPTIONS && (
        <ScoutReportManualPlayersOptionsForm
          scoutReportForm={scoutReportForm}
          onBack={handleBack}
          onNext={handleNext}
        />
      )}
      {step === ScoutReportStepEnum.CREATE_REPORT && (
        <ScoutReportForm onBack={handleBack} scoutReportForm={scoutReportForm} scoutReportType={scoutReportType} />
      )}
    </DialogBase>
  );
});

export default NewScoutReportDialog;
