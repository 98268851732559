import { useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import Next from 'assets/arrow-right.svg?react';
import { ProjectSchema } from 'lib/model';
import { useGetReportGetPlayerMetrics, useGetReportGetPlayerPositions } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback, useMemo } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';
import { ScoutReportFormValues } from '../interfaces';

interface ScoutReportManualPlayersOptionsFormProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  onNext: () => void;
  onBack: () => void;
}

function ScoutReportManualPlayersOptionsForm({
  onBack,
  onNext,
  scoutReportForm
}: ScoutReportManualPlayersOptionsFormProps) {
  const formState = useFormState(scoutReportForm);
  const position = useWatch({
    control: scoutReportForm.control,
    name: 'position'
  });

  const handleNext = useCallback(
    function handleNext() {
      onNext();
    },
    [onNext]
  );

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const playerPositions = useGetReportGetPlayerPositions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['playerPositions', project.id],
        staleTime: Infinity
      }
    }
  );
  const playerMetrics = useGetReportGetPlayerMetrics(
    { project_id: project.id!, player_position: position?.id as string | undefined },
    {
      query: {
        queryKey: ['playerMetrics', project.id],
        staleTime: Infinity,
        enabled: !!position
      }
    }
  );

  const playerPositionOptions = useMemo(
    () => playerPositions.data?.map((p) => ({ id: p, label: p }) as ValueOption) ?? [],
    [playerPositions]
  );
  const playerMetricOptions = useMemo(
    () => playerMetrics.data?.map((m) => ({ id: m, label: snakeCaseToWords(m) }) as ValueOption) ?? [],
    [playerMetrics]
  );

  return (
    <>
      <DialogContent>
        <form
          className="flex flex-col gap-6"
          id="scout-report-custom-players-form"
          onSubmit={scoutReportForm.handleSubmit(handleNext)}
        >
          <div className="flex flex-col gap-3">
            <span className="text-md font-semibold">Player position</span>
            <p className="text-sm">Choose the player position for players you want to add to your report.</p>
          </div>
          <SingleSelectInput
            loading={playerPositions.isPending}
            label="Player position"
            name="position"
            control={scoutReportForm.control}
            options={playerPositionOptions}
            rules={{ required: { value: true, message: 'Player position is required' } }}
            error={formState.errors.position}
          />
          <div className="flex flex-col gap-3">
            <span className="text-md font-semibold">Sort players by metric criteria</span>
            <p className="text-sm">
              All players will be sorted automatically based on the selected
              <b className="font-semibold"> metric criteria </b>
              for their position.
            </p>
          </div>
          <SingleSelectInput
            label="Metric Criteria"
            name="metric"
            control={scoutReportForm.control}
            options={playerMetricOptions}
            disabled={!position}
            loading={playerMetrics.isFetching}
            rules={{ required: { value: true, message: 'Metric Criteria is required' } }}
            error={formState.errors.metric}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={onBack}>
          <Back width={20} height={20} />
          <span>Back</span>
        </Button>
        <Button size="xl" isSubmitButton form="scout-report-custom-players-form">
          <span>Next</span>
          <Next width={20} height={20} />
        </Button>
      </DialogFooter>
    </>
  );
}

export default memo(ScoutReportManualPlayersOptionsForm);
