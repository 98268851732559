import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ProjectSchema, ReportCreateSchemaReportType } from 'lib/model';
import { useGetReportGetTeams, usePostReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import AsyncCombobox from 'modules/common/Form/AsyncCombobox';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AutocompleteOption, DialogProps } from 'utils/interfaces';

interface NewTeamReportFormValues {
  name: string;
  team: AutocompleteOption | null;
}

const NewTeamReportDialog = memo(function NewTeamReportDialog({ open, setOpen }: DialogProps) {
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const {
    handleSubmit: formSubmit,
    register,
    control,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm<NewTeamReportFormValues>({
    defaultValues: {
      name: '',
      team: null
    }
  });

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const { data: teams, isFetching: isTeamsFetching } = useGetReportGetTeams(
    {
      team_name: query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['teams', project.id, query],
        staleTime: 1000 * 60 * 5,
        enabled: query.length > 0
      }
    }
  );
  const teamOptions = useMemo(() => {
    return (
      teams?.teams?.map(
        (team: Record<string, unknown>) =>
          ({
            id: team.team_id,
            label: `${team.team_name}`,
            secondaryLabel: `${team.team_country}`
          }) as AutocompleteOption
      ) ?? []
    );
  }, [teams]);

  const { mutate: createReport, isPending: isCreating } = usePostReport();

  function handleSubmit(data: NewTeamReportFormValues) {
    if (!data.team) return;
    createReport(
      {
        data: {
          name: data.name,
          report_type: ReportCreateSchemaReportType.team,
          project: project!.id!,
          report_entities: [data.team.id as number]
        }
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['reports'] });
          navigate(`/reports/${res.id!}`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setError('root', { message: err.response?.data.error, type: 'backend-validation' });
          }
        }
      }
    );
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const selectedTeam = watch('team');
  useEffect(() => {
    if (selectedTeam) {
      setValue('name', `${selectedTeam.label} | ${selectedTeam.secondaryLabel}`);
    } else {
      setValue('name', '');
    }
  }, [selectedTeam, setValue]);

  return (
    <DialogBase title="New Team Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form className="flex w-full flex-col gap-10" id="new-team-report-form" onSubmit={formSubmit(handleSubmit)}>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Find Team</span>
            <AsyncCombobox
              rules={{ required: { value: true, message: 'Team is required.' } }}
              error={errors.team}
              loading={isTeamsFetching}
              setQuery={setQuery}
              name="team"
              control={control}
              label="Team"
              placeholder="Start typing: Team name"
              options={teamOptions}
            />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Report Name</span>
            <Input
              label="Enter report name"
              registerReturn={register('name', { required: 'Report Name is required' })}
              error={errors.name}
            />
          </div>
          {errors?.root && <ErrorMessage error={errors.root} />}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" disabled={isCreating} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-team-report-form" loading={isCreating}>
          <span>Create Team Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default NewTeamReportDialog;
