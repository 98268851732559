import Divider from 'modules/common/Divider';
import { memo } from 'react';
import ElementRow from '../../elements/ElementRow';
import MatchInfo from './MatchInfo';

import { MatchGeneralData } from '../interfaces';

interface MatchElementProps {
  match: MatchGeneralData;
}

const MatchElement = memo(function MatchElement({ match }: MatchElementProps) {
  return (
    <div className="flex flex-col gap-6">
      <MatchInfo match={match} />
      <Divider />
      <div className="flex flex-col gap-2">
        <ElementRow>
          <span className="text-tiny uppercase">STADIUM</span>
          <span>{match.stadium_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">REFEREE</span>
          <span>{match.referee_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">{match.home_team_name} COACH</span>
          <span>
            {match.home_team_manager_name ?? '-'}
          </span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">{match.away_team_name} COACH</span>
          <span>
            {match.away_team_manager_name ?? '-'}
          </span>
        </ElementRow>
      </div>
    </div>
  );
});

export default MatchElement;
