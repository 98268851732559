/* eslint-disable react/jsx-no-bind */
import { Group } from '@visx/group';
import { useParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Circle, Line } from '@visx/shape';
import { Text } from '@visx/text';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { ScaleLinear } from '@visx/vendor/d3-scale';
import { memo, useCallback, useMemo, useRef } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { getDefaultColor } from '../../../../helpers';
import ChartTooltip from '../ChartTooltip';
import { RadarChartPoint } from './interfaces';

interface RadarChartProps {
  points: RadarChartPoint[];
  fields: string[];
  subjectId: string;
  activeSubjects: Record<string, boolean>;
}

const RadarChart = memo(function RadarChart({ fields, activeSubjects, points, subjectId }: RadarChartProps) {
  const { parentRef, width } = useParentSize({ debounceTime: 150 });
  const height = width;
  const margin = 100;
  const xMax = width - margin - margin;
  const yMax = height - margin - margin;
  const radius = Math.min(xMax, yMax) / 2;

  const radialScale = scaleLinear<number>({
    range: [0, Math.PI * 2],
    domain: [0, 360]
  });

  const yMaxes = useMemo(
    function yMaxes() {
      const maxObj: Record<string, number> = {};
      for (let i = 0; i < fields.length; i++) {
        maxObj[fields[i]] = 0;
      }
      for (let i = 0; i < points.length; i++) {
        for (let j = 0; j < fields.length; j++) {
          const field = fields[j];
          if (points[i][field] > maxObj[field]) {
            maxObj[field] = points[i][field];
          }
        }
      }

      return maxObj;
    },
    [fields, points]
  );

  const yScales = useMemo(
    function yScales() {
      const scalesObj: Record<string, ScaleLinear<number, number>> = {};
      for (let i = 0; i < fields.length; i++) {
        scalesObj[fields[i]] = scaleLinear<number>({
          range: [30, radius],
          domain: [0, Math.max(yMaxes[fields[i]], 1)] // domain must be [0, 1] when max value is 0, this is due to d3-scale
        });
      }

      return scalesObj;
    },
    [fields, radius, yMaxes]
  );

  const subjectPolygonPoints = useMemo(
    function getMaximumValue() {
      const polygonPointsObj: Record<
        string,
        {
          points: {
            x: number;
            y: number;
          }[];
          pointString: string;
        }
      > = {};
      for (let i = 0; i < points.length; i++) {
        polygonPointsObj[points[i][subjectId]] = generatePolygonPoints(fields, points[i], yScales);
      }

      return polygonPointsObj;
    },
    [fields, points, subjectId, yScales]
  );

  const tooltipTimeout = useRef(0);
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen, tooltipLeft, tooltipTop } = useTooltip<{
    point: RadarChartPoint;
    fieldIndex: number;
  }>({
    tooltipOpen: false
  });
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    debounce: 300,
    scroll: true
  });

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const handleMouseEnter = useCallback(
    function handleMouseEnter(coords: { x: number; y: number }, point: RadarChartPoint, fieldIndex: number) {
      return () => {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        showTooltip({
          tooltipData: { point, fieldIndex },
          tooltipLeft: coords.x - 6 - 4 + width / 2, // 12 px padding, dot width = 8 px, -> (12 + 8)/2 (x translation) = 10 px
          tooltipTop: coords.y - 19 - 8 + height / 2 // 4 py padding, dot height 8 px, ~7px tooltip notch -> 19 px + 8px gap between tooltip and dot
        });
      };
    },
    [height, showTooltip, width]
  );

  return (
    <div className="relative w-full max-w-screen-md self-center" ref={parentRef}>
      <svg className="w-full overflow-visible" height={height} ref={containerRef}>
        <Group top={height / 2} left={width / 2}>
          {/* ZERO */}
          <Text
            x={0}
            y={0}
            fill="#6B7280"
            verticalAnchor="middle"
            textAnchor="middle"
            fontSize={10}
            fontWeight={600}
            paintOrder="stroke"
            fontFamily="Inter"
          >
            0
          </Text>
          {/* OUTER CIRCLE */}
          <Circle cx={0} cy={0} fill="none" r={radius} stroke="#D1D5DB" strokeWidth={1} />
          {/* FIELD LINES */}
          {fields.map((field, i) => {
            const degreesAngle = (i * 360) / fields.length;
            const angle = radialScale(degreesAngle);
            const startX = 35 * Math.cos(angle);
            const startY = 35 * Math.sin(angle);
            const endX = radius * Math.cos(angle);
            const endY = radius * Math.sin(angle);

            return (
              <Line
                from={{ x: startX, y: startY }}
                to={{ x: endX, y: endY }}
                key={`football-field-line-${field}`}
                stroke="#D1D5DB"
                strokeWidth={1}
              />
            );
          })}
          {/* FIELD LABELS */}
          {fields.map((field, i) => {
            const degreesAngle = (i * 360) / fields.length;
            const angle = radialScale(degreesAngle);
            const textRadius = width / 2 - margin / 2 + 12;
            const textX = textRadius * Math.cos(angle);
            let textY = textRadius * Math.sin(angle);

            const maxWordsPerLine = 14;
            const fieldWords = snakeCaseToWords(field)
              .split(' ')
              .flatMap((word) =>
                word.length >= maxWordsPerLine
                  ? [word.slice(0, maxWordsPerLine), '-' + word.slice(maxWordsPerLine)]
                  : word
              );
            const textRows: string[] = [];
            let row = '';
            for (const word of fieldWords) {
              if (row.length + word.length < maxWordsPerLine) {
                row += ` ${word}`;
              } else {
                textRows.push(row);
                row = word;
              }
            }
            if (row !== '') {
              textRows.push(row);
            }

            if (degreesAngle > 0 && degreesAngle < 180) {
              // BOTTOM
              textY = textY - 12;
            } else {
              // TOP
              textY = textY - textRows.length * 6;
            }

            return (
              <text
                key={`label-${field}`}
                x={textX}
                y={textY}
                dominantBaseline="middle"
                textAnchor="middle"
                fill="#6B7280"
                fontSize={10}
                fontWeight={600}
                paintOrder="stroke"
                fontFamily="Inter"
              >
                {textRows.map((textRow, index) => (
                  <tspan key={`${field}-text-row-${index}`} x={textX} dy={index > 0 ? '12px' : undefined}>
                    {textRow}
                  </tspan>
                ))}
              </text>
            );
          })}
          {/* MAXIMUM VALUE LABELS */}
          {Object.values(yMaxes).map((value, i) => {
            const degreesAngle = (i * 360) / fields.length;
            const angle = radialScale(degreesAngle);

            let textAngle;
            const textRadius = radius + 12;
            if (degreesAngle > 0 && degreesAngle < 180) {
              // BOTTOM
              textAngle = degreesAngle - 90;
            } else {
              // TOP
              textAngle = degreesAngle + 90;
            }

            const textX = textRadius * Math.cos(angle);
            const textY = textRadius * Math.sin(angle);

            return (
              <Text
                key={`max-label-${i}`}
                x={textX}
                y={textY}
                dominantBaseline="middle"
                textAnchor="middle"
                fill="#6B7280"
                fontSize={10}
                fontWeight={600}
                paintOrder="stroke"
                fontFamily="Inter"
                angle={textAngle}
              >
                {Number(value.toFixed(2))}
              </Text>
            );
          })}
          {/* POLYGONS */}
          {points.map((entity, i) => {
            if (!activeSubjects[entity[subjectId]]) return null;
            return (
              <polygon
                key={`polygon-${entity[subjectId]}`}
                points={subjectPolygonPoints[entity[subjectId]].pointString}
                fill={entity.color ?? getDefaultColor(i)}
                fillOpacity={0.1}
                stroke={entity.color ?? getDefaultColor(i)}
                strokeWidth={1}
              />
            );
          })}
          {/* POLYGONS' POINTS */}
          {points.flatMap((entity, i) => {
            return subjectPolygonPoints[entity[subjectId]].points.map((point, j) => (
              <circle
                key={`polygon-point-${entity[subjectId]}-${j}`}
                cx={point.x}
                cy={point.y}
                r={4}
                fill={entity.color ?? getDefaultColor(i)}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter(point, entity, j)}
              />
            ));
          })}
        </Group>
      </svg>
      <ChartTooltip open={tooltipOpen} left={tooltipLeft} top={tooltipTop} Tooltip={TooltipInPortal}>
        {tooltipData && (
          <>
            {tooltipData.point.player_name ? (
              <span>{tooltipData.point.player_name}</span>
            ) : (
              <span>{tooltipData.point.team_name}</span>
            )}
            <span>
              {snakeCaseToWords(fields[tooltipData.fieldIndex])}: {tooltipData.point[fields[tooltipData.fieldIndex]]}
            </span>
          </>
        )}
      </ChartTooltip>
    </div>
  );
});

function generatePolygonPoints(
  fields: string[],
  entity: RadarChartPoint,
  yScales: Record<string, (n: number) => number>
) {
  const step = (Math.PI * 2) / fields.length;
  const points: { x: number; y: number }[] = new Array(fields.length);
  const pointString: string = new Array(fields.length + 1).fill('').reduce((res, _, i) => {
    if (i > fields.length) return res;

    const value = entity[fields[i - 1]] ?? 0;

    const angle = (i - 1) * step;

    const xVal = yScales[fields[i - 1]](value) * Math.cos(angle);
    const yVal = yScales[fields[i - 1]](value) * Math.sin(angle);
    points[i - 1] = { x: xVal, y: yVal };
    res += `${xVal},${yVal} `;

    return res;
  });

  return { points, pointString };
}

export default RadarChart;
