/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { getDefaultColor, getReportGeneralColor } from '../../../../helpers';
import { RadarChartElementFormValues } from '../../dialogs/elementForms/interfaces';
import { AttributeValues } from '../interfaces';
import RadarChart from './RadarChart';
import { RadarChartPoint } from './interfaces';
import { PlayerGeneralData, ScoutPlayerData } from '../../reportCards/interfaces';

interface RadarChartResult {
  player_id?: number;
  player_name?: string;
  team_id: number;
  team_name: string;
  team_color: string;
  type: string;
  value: number;
}

interface RadarData {
  result: RadarChartResult[];
}

interface RadarChartElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const RadarChartElement = memo(function RadarChartElement({ report, element }: RadarChartElementProps) {
  const attributeValues = element.attribute_values as AttributeValues<RadarChartElementFormValues>;
  const isPlayer = useMemo(() => attributeValues.attribute_type[0] !== 'team', [attributeValues.attribute_type]);
  const subjectId = useMemo(() => (isPlayer ? 'player_id' : 'team_id'), [isPlayer]);

  const { result: results } = element.entity_data! as RadarData;
  const [activeSubjects, setActiveSubjects] = useState<Record<string, boolean>>(
    results.reduce((acc, curr) => ({ ...acc, [curr[subjectId]!]: true }), {})
  );
  const { points, fields } = useMemo(() => {
    // Changed dataObj from record to map because Map preserves order in which items are added
    const dataObj = new Map<number, RadarChartPoint>();
    const fieldsSet = new Set<string>();

    // HACK: Initialize players in the order of scout/player comparison data so the colors match
    if (report.report_type === 'scout') {
      const filteredPlayers = report.general_data!.players_filtered as ScoutPlayerData[];
      const manualPlayers = report.general_data!.players_manual as ScoutPlayerData[];
      const combinedPlayers = [...filteredPlayers, ...manualPlayers];
      for (const player of combinedPlayers) {
        dataObj.set(player.player_id!, {
          player_id: player.player_id,
          player_name: player?.player_name,
          team_id: player.team_id,
          team_name: player.team_name,
          team_color: player.team_color
        });
      }
    } else if (report.report_type === 'player_comparison') {
      const player1 = report.general_data!.player1 as PlayerGeneralData;
      const player2 = report.general_data!.player2 as PlayerGeneralData;
      dataObj.set(player1.player_id, {
        player_id: player1.player_id,
        player_name: player1?.player_name,
        team_id: player1.team_id,
        team_name: player1.team_name,
        team_color: player1.team_color
      });
      dataObj.set(player2.player_id, {
        player_id: player2.player_id,
        player_name: player2?.player_name,
        team_id: player2.team_id,
        team_name: player2.team_name,
        team_color: player2.team_color
      });
    }

    for (const result of results) {
      if (!dataObj.has(result[subjectId]!)) {
        dataObj.set(result[subjectId]!, {
          player_id: result?.player_id,
          player_name: result?.player_name,
          team_id: result.team_id,
          team_name: result.team_name,
          team_color: result.team_color
        });
      }

      const object = dataObj.get(result[subjectId]!);
      object![result.type] = Number(result.value);

      fieldsSet.add(result.type);
    }
    return { points: Array.from(dataObj.values()), fields: Array.from(fieldsSet) };
  }, [report.report_type, report.general_data, results, subjectId]);

  return (
    <div className="flex flex-col gap-3">
      <p className="text-xs">Click to select and deselect {isPlayer ? 'players' : 'teams'}.</p>
      <div
        style={{ gridTemplateColumns: `repeat(${Math.min(4, points.length)}, minmax(0, 1fr))` }}
        className="grid gap-3"
      >
        {points.map((entity, i) => (
          <button
            key={entity[subjectId]}
            className={twJoin(
              'flex grow basis-[calc(25%-9px)] items-center gap-2 rounded-md border px-3 py-2',
              activeSubjects[entity[subjectId]!] ? 'border-brand-800 bg-brand-50' : 'border-transparent bg-gray-50'
            )}
            onClick={() => {
              setActiveSubjects((old) => ({ ...old, [entity[subjectId]!]: !activeSubjects[entity[subjectId]!] }));
            }}
          >
            <span
              className="size-3 rounded-full"
              style={{ backgroundColor: entity.team_color ?? getDefaultColor(i) }}
            />
            <span className="text-xs font-medium">{isPlayer ? entity.player_name : entity.team_name}</span>
          </button>
        ))}
      </div>
      <RadarChart points={points} fields={fields} activeSubjects={activeSubjects} subjectId={subjectId} />
    </div>
  );
});

export default RadarChartElement;
