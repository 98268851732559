import { useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import Next from 'assets/arrow-right.svg?react';
import { ProjectSchema } from 'lib/model';
import {
  useGetReportColumnExists,
  useGetReportGetCompetitions,
  useGetReportGetCountries,
  useGetReportGetPlayerMetrics,
  useGetReportGetPlayerPositions,
  useGetReportGetPlayerSubpositions,
  useGetReportGetTeams
} from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Fieldset from 'modules/common/Form/Fieldset';
import Input from 'modules/common/Form/Input';
import MultipleSelectAsyncCombobox from 'modules/common/Form/MultipleSelectAsyncCombobox';
import MultipleSelectCombobox from 'modules/common/Form/MultipleSelectCombobox';
import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import Slider from 'modules/common/Form/Slider';
import ToggleFieldset from 'modules/common/Form/ToggleFieldset';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { AutocompleteOption, Competition, Team, ValueOption } from 'utils/interfaces';
import { ScoutReportFormValues } from '../interfaces';

interface ScoutReportFilteredPlayersOptionsFormProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  onNext: () => void;
  onBack: () => void;
}

function ScoutReportFilteredPlayersOptionsForm({
  onBack,
  onNext,
  scoutReportForm
}: ScoutReportFilteredPlayersOptionsFormProps) {
  const formState = useFormState(scoutReportForm);
  const position = useWatch({
    control: scoutReportForm.control,
    name: 'position'
  });

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;

  const playerPositions = useGetReportGetPlayerPositions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['playerPositions', project.id],
        staleTime: Infinity
      }
    }
  );
  const playerSubPositions = useGetReportGetPlayerSubpositions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['playerSubPositions', project.id],
        staleTime: Infinity
      }
    }
  );
  const playerMetrics = useGetReportGetPlayerMetrics(
    { project_id: project.id!, player_position: position?.id as string | undefined },
    {
      query: {
        queryKey: ['playerMetrics', project.id, position?.id],
        staleTime: Infinity,
        enabled: !!position
      }
    }
  );
  const competitions = useGetReportGetCompetitions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['competitions', project.id],
        staleTime: Infinity
      }
    }
  );
  const [teamQuery, setTeamQuery] = useState<string>('');
  const teams = useGetReportGetTeams(
    {
      team_name: teamQuery,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['teams', project.id, teamQuery],
        staleTime: 1000 * 60 * 5,
        enabled: teamQuery.length > 0
      }
    }
  );
  const countries = useGetReportGetCountries(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['countries', project.id],
        staleTime: Infinity
      }
    }
  );
  const existHeightColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'height' },
    {
      query: {
        queryKey: ['existHeightColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingWeightColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'weight' },
    {
      query: {
        queryKey: ['existingWeightColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingMarketValueColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'market_value' },
    {
      query: {
        queryKey: ['existingMarketValueColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingBirthDateColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'birth_date' },
    {
      query: {
        queryKey: ['existingBirthDateColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingCountryColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'country' },
    {
      query: {
        queryKey: ['existingCountryColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingNationalityColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'nationality' },
    {
      query: {
        queryKey: ['existingNationalityColumn', project.id],
        staleTime: Infinity
      }
    }
  );

  const playerPositionOptions = useMemo(
    () => playerPositions.data?.map((pos) => ({ id: pos, label: pos }) as ValueOption) ?? [],
    [playerPositions]
  );
  const playerSubPositionOptions = useMemo(
    () =>
      !position
        ? []
        : (playerSubPositions.data?.positions
            ?.filter((subpos) => subpos.primary_position! === position.id)
            .map(
              (subpos) =>
                ({
                  id: subpos.sub_position,
                  label: subpos.sub_position
                }) as ValueOption
            ) ?? []),
    [playerSubPositions, position]
  );
  const playerMetricOptions = useMemo(
    () => playerMetrics.data?.map((metr) => ({ id: metr, label: snakeCaseToWords(metr) }) as ValueOption) ?? [],
    [playerMetrics]
  );
  const teamOptions = useMemo(() => {
    return (
      teams.data?.teams?.map(
        (team: Team & { team_country: string }) =>
          ({
            id: team.team_id,
            label: team.team_name,
            secondaryLabel: team.team_country
          }) as AutocompleteOption
      ) ?? []
    );
  }, [teams]);
  const competitionOptions = useMemo(
    () =>
      competitions.data?.map(
        (competition: Competition) =>
          ({
            id: competition.competition_id,
            label: competition.competition_name ?? 'NO NAME',
            secondaryLabel: competition.competition_country
          }) satisfies AutocompleteOption
      ) ?? [],
    [competitions]
  );
  const countryOptions = useMemo(
    () => countries.data?.map((country) => ({ id: country, label: country }) as ValueOption) ?? [],
    [countries.data]
  );

  useEffect(() => {
    scoutReportForm.setValue('subPositions', []);
  }, [position, scoutReportForm]);

  const handleNext = useCallback(
    function handleNext() {
      onNext();
    },
    [onNext]
  );

  return (
    <>
      <DialogContent>
        <form
          className="flex flex-col gap-6"
          id="scout-report-player-filters"
          onSubmit={scoutReportForm.handleSubmit(handleNext)}
        >
          <div className="flex flex-col gap-3">
            <span className="text-md font-medium">Filter</span>
            <p className="text-sm">Enter preferences to find best players.</p>
          </div>
          <Input
            label="Number of filtered players (Max 20)"
            registerReturn={scoutReportForm.register('numberOfFilteredPlayers', {
              min: { value: 0, message: 'Minimum of 0 players can be filtered' },
              max: { value: 20, message: 'Maximum of 20 players can be filtered' },
              required: { value: true, message: 'Number of filtered players is required' },
              valueAsNumber: true
            })}
            type="number"
            unit="Players"
            error={formState.errors.numberOfFilteredPlayers}
          />
          <Fieldset legend="Player position">
            <SingleSelectInput
              control={scoutReportForm.control}
              name="position"
              label="Player Position"
              options={playerPositionOptions}
              loading={playerPositions.isPending}
              rules={{ required: { value: true, message: 'Player position is required' } }}
              error={formState.errors.position}
            />
            <MultipleSelectInput
              control={scoutReportForm.control}
              name="subPositions"
              label="Player Sub Positions"
              disabled={!position}
              options={playerSubPositionOptions}
              loading={playerSubPositions.isPending}
            />
            <div className="flex flex-col gap-3">
              <span className="text-md font-medium">Player ranking</span>
              <p className="text-sm">
                All the filtered players will be ranked automatically based on the total value of the selected
                <b className="font-semibold"> metric criteria</b> in the latest available season.
              </p>
            </div>
            <SingleSelectInput
              name="metric"
              control={scoutReportForm.control}
              label="Metric Criteria"
              disabled={!position}
              options={playerMetricOptions}
              loading={playerMetrics.isFetching}
              rules={{ required: { value: true, message: 'Metric Criteria is required' } }}
              error={formState.errors.metric}
            />
          </Fieldset>
          <ToggleFieldset
            control={scoutReportForm.control}
            name="isCompetitionsAndTeamsEnabled"
            legend="Competitions and Teams"
          >
            <MultipleSelectCombobox
              loading={competitions.isFetching}
              control={scoutReportForm.control}
              label="Competitions"
              name="competitions"
              placeholder="All Competitions"
              options={competitionOptions}
            />
            <MultipleSelectAsyncCombobox
              loading={teams.isFetching}
              setQuery={setTeamQuery}
              control={scoutReportForm.control}
              label="Team"
              name="teams"
              placeholder="All Teams"
              options={teamOptions}
            />
          </ToggleFieldset>
          <ToggleFieldset
            control={scoutReportForm.control}
            name="isPlaytimeExperienceEnabled"
            legend="Playtime Experience"
          >
            <Input
              label="Minimum games played (in last available season)"
              registerReturn={scoutReportForm.register('minPlayedGames', { min: 0, valueAsNumber: true })}
              unit="Games"
            />
            <span className="text-md font-medium">Minutes played per match (Average)</span>
            <Slider unit="min" control={scoutReportForm.control} name="avgMinutesPlayed" defaultValue={[0, 120]} />
          </ToggleFieldset>
          <ToggleFieldset control={scoutReportForm.control} name="isMarketValueEnabled" legend="Market Value">
            {existingMarketValueColumn.data === true && (
              <Slider
                unit="euro"
                control={scoutReportForm.control}
                name="avgMarketValue"
                defaultValue={[0, 200_000_000]}
              />
            )}
            {existingMarketValueColumn.data === false && (
              <span className="text-sm text-red-500">Market value is not available in the current dataset</span>
            )}
          </ToggleFieldset>
          <ToggleFieldset
            control={scoutReportForm.control}
            name="isPhysicalAttributesEnabled"
            legend="Physical Attributes"
          >
            <span className="text-md font-medium">Age</span>
            {(existingBirthDateColumn.data === true) &&
              <Slider unit="yrs" control={scoutReportForm.control} name="age" defaultValue={[0, 100]} />
            }
            {(existingBirthDateColumn.data === false) &&
              <span className="text-sm text-red-500">Age is not available in the current dataset</span>
            }
            <span className="text-md font-medium">Height</span>
            {(existHeightColumn.data === true) &&
              <Slider unit="cm" control={scoutReportForm.control} name="height" defaultValue={[0, 250]} />
            }
            {(existHeightColumn.data === false) &&
              <span className="text-sm text-red-500">Height is not available in the current dataset</span>
            }
            <span className="text-md font-medium">Weight</span>
            {(existingWeightColumn.data === true) &&
              <Slider unit="kg" control={scoutReportForm.control} name="weight" defaultValue={[0, 200]} />
            }
            {(existingWeightColumn.data === false) &&
              <span className="text-sm text-red-500">Weight is not available in the current dataset</span>
            }
          </ToggleFieldset>

          <ToggleFieldset control={scoutReportForm.control} name="isNationalityEnabled" legend="Nationality">
            {existingCountryColumn.data === false && (
              <span className="text-sm text-red-500">Country of birth is not available in the current dataset</span>
            )}
            {existingCountryColumn.data === true && (
              <MultipleSelectInput
                name="countryOfBirth"
                control={scoutReportForm.control}
                label="Country of birth"
                placeholder="All Countries"
                options={countryOptions}
                loading={countries.isPending}
              />
            )}
            {existingNationalityColumn.data === false && (
              <span className="text-sm text-red-500">Nationality is not available in the current dataset</span>
            )}
            {existingNationalityColumn.data === true && (
              <MultipleSelectInput
                name="nationality"
                control={scoutReportForm.control}
                label="Nationality"
                placeholder="All Nationalities"
                options={countryOptions}
                loading={countries.isPending}
              />
            )}
          </ToggleFieldset>
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={onBack}>
          <Back width={20} height={20} />
          <span>Back</span>
        </Button>
        <Button size="xl" isSubmitButton form="scout-report-player-filters">
          <span>Filter</span>
          <Next width={20} height={20} />
        </Button>
      </DialogFooter>
    </>
  );
}

export default memo(ScoutReportFilteredPlayersOptionsForm);
