import { useQueryClient } from '@tanstack/react-query';
import Edit from 'assets/edit.svg?react';
import { UserAccountSchema } from 'lib/model';
import { usePutUser } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import ImageUpload from 'modules/common/ImageUpload';
import { memo, useEffect, useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import DeleteUserButton from './dialogs/DeleteUserButton';
import EmailChangeDialog from './dialogs/EmailChangeDialog';
import PasswordChangeDialog from './dialogs/PasswordChangeDialog';
import UsernameChangeDialog from './dialogs/UsernameChangeDialog';

function Account() {
  const queryClient = useQueryClient();
  const [user, setUser] = useState(queryClient.getQueryData<UserAccountSchema>(['user']));
  const [images, setImages] = useState([] as ImageListType);
  const [usernameChangeOpen, setUsernameChangeOpen] = useState(false);
  const [emailChangeOpen, setEmailChangeOpen] = useState(false);
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);

  function changeUsername() {
    setUsernameChangeOpen(true);
  }

  function changeEmail() {
    setEmailChangeOpen(true);
  }

  function changePassword() {
    setPasswordChangeOpen(true);
  }

  const { mutate: updateUser } = usePutUser();

  function updateUsername(firstName: string, lastName: string) {
    updateUser(
      { data: { first_name: firstName, last_name: lastName } },
      {
        onSuccess: () => {
          setUsernameChangeOpen(false);
          toast.success('Name successfully changed');
          queryClient.invalidateQueries({ queryKey: ['user'] }).then(() => {
            setUser(queryClient.getQueryData<UserAccountSchema>(['user']));
          });
        }
      }
    );
  }

  useEffect(() => {
    if (images.length) {
      updateUser(
        { data: { user_image: images[0].dataURL! } },
        {
          onSuccess: () => {
            toast.success('User image successfully updated.');
          }
        }
      );
    }
  }, [images, updateUser]);

  return (
    <section className="flex flex-col gap-10 p-10">
      <UsernameChangeDialog
        open={usernameChangeOpen}
        setOpen={setUsernameChangeOpen}
        user={user!}
        updateUsername={updateUsername}
      />

      <EmailChangeDialog open={emailChangeOpen} setOpen={setEmailChangeOpen} />

      <PasswordChangeDialog open={passwordChangeOpen} setOpen={setPasswordChangeOpen} />

      <span className="text-md font-semibold">Account Information</span>

      <ImageUpload
        images={images}
        setImages={setImages}
        initials={user!.first_name!.charAt(0) + user!.last_name!.charAt(0)}
        imageUrl={user?.user_image_path}
      />

      <div className="flex w-[612px] flex-col gap-6">
        <div className="flex flex-col gap-2 rounded-md bg-gray-50 p-6">
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-500">Full name</span>
            <div className="flex cursor-pointer items-center gap-2" onClick={changeUsername}>
              <span className="text-sm text-brand-800">Edit</span>
              <Edit width={16} height={16} className="fill-brand-800" />
            </div>
          </div>
          <span className="text-md font-medium">{user?.first_name + ' ' + user?.last_name}</span>
        </div>

        <div className="flex flex-col gap-2 rounded-md bg-gray-50 p-6">
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-500">Email</span>
            <div className="flex cursor-pointer items-center gap-2" onClick={changeEmail}>
              <span className="text-sm text-brand-800">Edit</span>
              <Edit width={16} height={16} className="fill-brand-800" />
            </div>
          </div>
          <span className="text-md font-medium">{user?.email}</span>
        </div>

        <Button variant="secondary" size="xl" onClick={changePassword}>
          Change password
        </Button>
        <DeleteUserButton />
      </div>
    </section>
  );
}

export default memo(Account);
