import { getOpposingTeams } from 'modules/reports/helpers';
import { memo, useCallback, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { AttributeValues } from '../../../elements/interfaces';
import {
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from '../../../reportCards/interfaces';
import {
  attributeValuesCompetition,
  attributeValuesMatchDetailed,
  attributeValuesOption,
  attributeValuesPlayersHeatMap
} from '../helpers';
import {
  ComparisonHeatMapElementFormValues,
  ElementFormProps,
  HeatMapElementFormValues,
  MatchHeatMapElementFormValues,
  SingleHeatMapElementFormValues
} from '../interfaces';
import ComparisonHeatMapElementForm from './ComparisonHeatMapElementForm';
import MatchHeatMapElementForm from './MatchHeatMapElementForm';
import SingleHeatMapElementForm from './SingleHeatMapElementForm';

const HeatMapElementForm = memo(function HeatMapElementForm({
  report,
  template,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<HeatMapElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<HeatMapElementFormValues>;
    if (values.home_players) {
      defaultValues.home_players = attributeValuesPlayersHeatMap(
        template.attribute_choices!.home_players,
        values.home_players as number[]
      );
    }
    if (values.away_players) {
      defaultValues.away_players = attributeValuesPlayersHeatMap(
        template.attribute_choices!.away_players,
        values.away_players as number[]
      );
    }
    if (values.players) {
      defaultValues.players = attributeValuesPlayersHeatMap(
        template.attribute_choices!.players,
        values.players as number[]
      );
    }
    if (values.players_comparison) {
      defaultValues.players_comparison = attributeValuesPlayersHeatMap(
        template.attribute_choices!.players_comparison,
        values.players_comparison as number[]
      );
    }
    // if (values.time_period) {
    //   defaultValues.time_period = attributeValuesOption(values.time_period as string[]);
    // }
    if (values.has_comparison) {
      defaultValues.has_comparison = values.has_comparison[0] as boolean;
    }
    if (values.seasons) {
      defaultValues.seasons = attributeValuesOption(values.seasons as string[]);
    }
    if (values.seasons_comparison) {
      defaultValues.seasons_comparison = attributeValuesOption(values.seasons_comparison as string[]);
    }
    if (values.competitions) {
      defaultValues.competitions = attributeValuesCompetition(
        template.attribute_choices!.competitions,
        values.competitions as number[]
      );
    }
    if (values.competitions_comparison) {
      defaultValues.competitions_comparison = attributeValuesCompetition(
        template.attribute_choices!.competitions_comparison,
        values.competitions_comparison as number[]
      );
    }
    if (values.matches) {
      defaultValues.matches = attributeValuesMatchDetailed(
        template.attribute_choices!.matches,
        values.matches as number[]
      );
    }
    if (values.matches_comparison) {
      defaultValues.matches_comparison = attributeValuesMatchDetailed(
        template.attribute_choices!.matches_comparison,
        values.matches_comparison as number[]
      );
    }
    if (values.home_phase) {
      defaultValues.home_phase = attributeValuesOption(values.home_phase as string[]);
    }
    if (values.away_phase) {
      defaultValues.away_phase = attributeValuesOption(values.away_phase as string[]);
    }
    if (values.home_pressure) {
      defaultValues.home_pressure = attributeValuesOption(values.home_pressure as string[]);
    }
    if (values.away_pressure) {
      defaultValues.away_pressure = attributeValuesOption(values.away_pressure as string[]);
    }
  }
  const heatMapElementForm = useForm<HeatMapElementFormValues>({
    defaultValues
  });

  const addElement = useCallback(
    function addElement(data: HeatMapElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );
  const [homeTeam, awayTeam] = getOpposingTeams(report)!;

  const heatMapForm = useMemo(() => {
    if (report.report_type === 'match') {
      return (
        <MatchHeatMapElementForm
          template={template}
          heatMapElementForm={heatMapElementForm as UseFormReturn<MatchHeatMapElementFormValues>}
          homeTeam={homeTeam.team_name}
          awayTeam={awayTeam!.team_name}
        />
      );
    } else if (report.report_type === 'player' || report.report_type === 'team') {
      const subject =
        report.report_type === 'player'
          ? (report.general_data as PlayerGeneralData).player_name
          : (report.general_data as TeamGeneralData).team_name;
      return (
        <SingleHeatMapElementForm
          template={template}
          heatMapElementForm={heatMapElementForm as UseFormReturn<SingleHeatMapElementFormValues>}
          subject={subject}
        />
      );
    } else if (report.report_type === 'player_comparison' || report.report_type === 'team_comparison') {
      const subject1 =
        report.report_type === 'player_comparison'
          ? (report.general_data as PlayerComparisonGeneralData).player1.player_name
          : (report.general_data as TeamComparisonGeneralData).team1.team_name;
      const subject2 =
        report.report_type === 'player_comparison'
          ? (report.general_data as PlayerComparisonGeneralData).player2.player_name
          : (report.general_data as TeamComparisonGeneralData).team2.team_name;
      return (
        <ComparisonHeatMapElementForm
          template={template}
          heatMapElementForm={heatMapElementForm as UseFormReturn<ComparisonHeatMapElementFormValues>}
          subject1={subject1}
          subject2={subject2}
        />
      );
    }
  }, [awayTeam, heatMapElementForm, homeTeam.team_name, report.general_data, report.report_type, template]);

  return (
    <form
      className="flex w-full flex-col gap-10"
      id="new-element-form"
      onSubmit={heatMapElementForm.handleSubmit(addElement)}
    >
      {heatMapForm}
    </form>
  );
});

export default HeatMapElementForm;
