import Info from 'assets/info.svg?react';
import Plus from 'assets/plus.svg?react';
import Button from 'modules/common/Button';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

const DummyDataBanner = memo(function DummyDataBanner() {
  const navigate = useNavigate();

  function handleConnect() {
    navigate('/data-sources/all');
  }

  return (
    <div className="flex items-center justify-between gap-6 rounded-xl bg-white p-10">
      <div className="flex gap-6">
        <Info width={64} height={64} className="min-h-16 min-w-16 fill-gray-300" />
        <p className="text-md font-medium">
          This table is filled with <span className="font-bold">dummy data</span> for exploration purposes.
          <br />
          <span className="font-bold">Connect data sources</span> for full access of the latest and most up-to-date
          information.
        </p>
      </div>
      <Button variant="primary" size="xl" isFullWidth={false} onClick={handleConnect}>
        <Plus width={20} height={20} />
        <span>Connect Data Sources</span>
      </Button>
    </div>
  );
});

export default DummyDataBanner;
