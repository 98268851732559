import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ValueOption } from 'utils/interfaces';
import { AttributeValues } from '../../elements/interfaces';

import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { attributeValuesEvents } from './helpers';
import { ElementFormProps, ElementFormValues, EventMapEvent } from './interfaces';

interface EventMapElementFormValues extends ElementFormValues {
  events: ValueOption | null;
}

const EventMapForm = memo(function VersusElementForm({ template, onSubmitForm, element }: ElementFormProps) {
  const defaultValues: Partial<EventMapElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<EventMapElementFormValues>;
    if (values.events) {
      defaultValues.events = attributeValuesEvents(template.attribute_choices!.events, values.events as string[]);
    }
  }

  const { handleSubmit, control, formState } = useForm<EventMapElementFormValues>({
    defaultValues
  });

  const addElement = useCallback(
    function addElement(data: EventMapElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  const eventOptions: ValueOption[] = useMemo(() => {
    return template.attribute_choices!.events.map((event: EventMapEvent) => {
      if (event.event_type === 'Goal') {
        return {
          id: event.minutes_elapsed,
          label: `${event.minutes_elapsed}' | ${event.player_name} | ${event.team_name}`,
          value: event
        } satisfies ValueOption;
      }
      return {
        id: event.minutes_elapsed,
        label: event.event_type,
        value: event
      } satisfies ValueOption;
    });
  }, [template.attribute_choices]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <SingleSelectInput
        name="events"
        control={control}
        label={'Event'}
        options={eventOptions}
        rules={{ required: { value: true, message: 'Event is required.' } }}
        error={formState.errors.events}
      />
    </form>
  );
});

export default EventMapForm;
