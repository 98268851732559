import useAuth from 'contexts/auth/authContext';
import { memo } from 'react';
import DashboardRecentlyEditedReports from './RecentlyEditedReports/DashboardRecentlyEditedReports';
import DashboardRecentlyViewedReports from './RecentlyViewedReports/DashboardRecentlyViewedReports';

/*
TODO: add demo reports if free trial project?
TODO: finish recently viewed reports
*/

function DashboardReports() {
  const { isViewer } = useAuth();

  // TODO: insert demo reports here (1st in order)

  if (isViewer) {
    return <DashboardRecentlyViewedReports />;
  }

  return <DashboardRecentlyEditedReports />;
}

export default memo(DashboardReports);
