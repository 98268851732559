import Report from 'assets/report.svg?react';
import { useGetReportLastEdited } from 'lib/report/report';
import ReportCard from 'modules/reports/reports/components/reportCard/ReportCard';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import DashboardCardsSkeleton from '../../DashboardCardsSkeleton';

function DashboardRecentlyViewedReports() {
  const { data: lastEditedReports, isFetching: isLastEditedReportsPending } = useGetReportLastEdited(
    {
      num_last_edited_reports: 4
    },
    {
      query: {
        queryKey: ['reports', 'lastEdited'],
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false
      }
    }
  );

  if (isLastEditedReportsPending) {
    return <DashboardCardsSkeleton />;
  }

  let reports;
  if (lastEditedReports?.objects && lastEditedReports.objects.length > 0) {
    reports = lastEditedReports?.objects?.map((report) => <ReportCard key={report.id} report={report} />);
  } else {
    reports = (
      <div className="flex items-center gap-6 rounded-xl bg-white p-10">
        <Report className="size-16 fill-gray-300" />
        <span className="text-md font-medium">No reports.</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-3">
          <Report className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">Recently viewed reports</span>
        </div>
        <div className="flex gap-4">
          <NavLink className={'text-xs font-semibold text-brand-800'} to={'/reports?ownership=my_reports'}>
            My reports
          </NavLink>
          <NavLink className={'text-xs font-semibold text-brand-800'} to={'/reports'}>
            See All
          </NavLink>
        </div>
      </div>
      <div className="grid grid-cols-cards-smaller gap-6">{reports}</div>
    </div>
  );
}

export default memo(DashboardRecentlyViewedReports);
