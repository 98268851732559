import DataSourcesFiltersProvider from 'contexts/dataSourcesFilters/DataSourcesFiltersProvider';
import { FilteredDataSourcesProvider } from 'contexts/filteredDataSources/FilteredDataSourcesProvider';
import { SelectedDataSource, SelectedDataSourcesContext } from 'contexts/selectedDataSourcesContext';
import { useGetProjectDataTable } from 'lib/data-table/data-table';
import { memo, useState } from 'react';
import { Outlet } from 'react-router';
import DataSourcesHeader from './components/DataSourcesHeader';

const DataSourcesLayout = memo(function DataSourcesLayout() {
  const [selectedDataSources, setSelectedDataSources] = useState<SelectedDataSource[]>([]);

  useGetProjectDataTable({
    query: {
      queryKey: ['dataTables'],
      staleTime: Infinity
    }
  });

  return (
    <DataSourcesFiltersProvider>
      <FilteredDataSourcesProvider>
        <div className="flex w-full flex-col items-center">
          <DataSourcesHeader />
          <Outlet context={{ selectedDataSources, setSelectedDataSources } satisfies SelectedDataSourcesContext} />
        </div>
      </FilteredDataSourcesProvider>
    </DataSourcesFiltersProvider>
  );
});

export default DataSourcesLayout;
