import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ProjectSchema, ReportCreateSchemaReportType } from 'lib/model';
import { useGetReportGetMatches, usePostReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import AsyncCombobox from 'modules/common/Form/AsyncCombobox';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { conditionallyRender } from 'utils/helpers';
import { AutocompleteOption, DialogProps } from 'utils/interfaces';

interface NewMatchReportFormValues {
  name: string;
  match: AutocompleteOption | null;
}

const NewMatchReportDialog = memo(function NewMatchReportDialog({ open, setOpen }: DialogProps) {
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const {
    handleSubmit: formSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setError,
    setValue
  } = useForm<NewMatchReportFormValues>({
    defaultValues: {
      match: null,
      name: ''
    }
  });

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const { data: matches, isFetching } = useGetReportGetMatches(
    {
      match_name: query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['matches', project.id, query],
        staleTime: 1000 * 60 * 5,
        enabled: query.length > 0
      }
    }
  );
  const matchOptions = useMemo(() => {
    return (
      matches?.matches?.map(
        (match) =>
          ({
            id: String(match.match_id),
            // Using == operator to check for nullish constraints while letting 0 through
            // eslint-disable-next-line eqeqeq
            label: `${match.home_team} - ${match.away_team}${conditionallyRender(!(match.home_team_score == null), `(${match.home_team_score}:${match.away_team_score})`)}`,
            secondaryLabel: `${new Date(match.date!).toLocaleDateString()}${conditionallyRender(!!match.competition_name, `| ${match.competition_name}`)}`
          }) as AutocompleteOption
      ) ?? []
    );
  }, [matches]);

  const { mutate: createReport, isPending: isCreating } = usePostReport();

  function handleSubmit(data: NewMatchReportFormValues) {
    if (!data.match) return;
    createReport(
      {
        data: {
          name: data.name,
          report_type: ReportCreateSchemaReportType.match,
          project: project!.id!,
          report_entities: [data.match.id as number] // required
        }
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['reports'] });
          navigate(`/reports/${res.id!}`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setError('root', { message: err.response?.data.error, type: 'backend-validation' });
          }
        }
      }
    );
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const selectedMatch = watch('match');
  useEffect(() => {
    if (selectedMatch) {
      setValue('name', `${selectedMatch.label} - ${selectedMatch.secondaryLabel}`);
    } else {
      setValue('name', '');
    }
  }, [selectedMatch, setValue]);

  return (
    <DialogBase title="New Match Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form className="flex w-full flex-col gap-10" id="new-match-report-form" onSubmit={formSubmit(handleSubmit)}>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Find Match</span>
            <AsyncCombobox
              rules={{ required: { value: true, message: 'Match is required.' } }}
              error={errors.match}
              setQuery={setQuery}
              name="match"
              control={control}
              label="Match"
              placeholder="Start typing: Home team | Away team"
              options={matchOptions}
              loading={isFetching}
            />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Report Name</span>
            <Input
              label="Enter report name"
              registerReturn={register('name', { required: 'Report Name is required' })}
              error={errors.name}
            />
          </div>
          {errors?.root && <ErrorMessage error={errors.root} />}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" disabled={isCreating} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-match-report-form" loading={isCreating}>
          <span>Create Match Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default NewMatchReportDialog;
