import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';
import { AttributeValues } from '../../elements/interfaces';

import { attributeValuesOptions } from './helpers';
import { ElementFormProps, ElementFormValues } from './interfaces';

interface VersusElementFormValues extends ElementFormValues {
  metrics: ValueOption[];
}

const VersusElementForm = memo(function VersusElementForm({ template, onSubmitForm, element }: ElementFormProps) {
  const defaultValues: Partial<VersusElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<VersusElementFormValues>;
    if (values.metrics) {
      defaultValues.metrics = attributeValuesOptions(values.metrics as string[]);
    }
  }

  const { handleSubmit, watch, control, formState } = useForm<VersusElementFormValues>({
    defaultValues
  });
  const attributeType = watch('attribute_type');

  const addElement = useCallback(
    function addElement(data: VersusElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <MultipleSelectInput
        key={'metrics'}
        name="metrics"
        control={control}
        label={'Choose metrics to aggregate'}
        disabled={attributeType === null}
        options={template.attribute_choices!.metrics.map(
          (option: string) =>
            ({
              label: snakeCaseToWords(option),
              id: option
            }) as ValueOption
        )}
        rules={{
          validate: {
            minLength: (values: ValueOption[]) => values.length > 0 || 'At least one metric is required!'
          }
        }}
        error={formState.errors.metrics}
      />
    </form>
  );
});

export default VersusElementForm;
