import { ReportElementTemplateSchema } from 'lib/model';
import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useMemo } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { Competition, MatchDetailed, Player, ValueOption } from 'utils/interfaces';

import { ComparisonHeatMapElementFormValues } from '../interfaces';

interface ComparisonHeatMapElementFormProps {
  template: ReportElementTemplateSchema;
  heatMapElementForm: UseFormReturn<ComparisonHeatMapElementFormValues>;
  subject1: string;
  subject2: string;
}

const ComparisonHeatMapElementForm = memo(function ComparisonHeatMapElementForm({
  template,
  heatMapElementForm,
  subject1,
  subject2
}: ComparisonHeatMapElementFormProps) {
  const formState = useFormState(heatMapElementForm);

  const season = useWatch({
    control: heatMapElementForm.control,
    name: 'seasons'
  }) as ValueOption | null;
  const competition = useWatch({
    control: heatMapElementForm.control,
    name: 'competitions'
  }) as ValueOption | null;
  const match = useWatch({
    control: heatMapElementForm.control,
    name: 'matches'
  }) as ValueOption | null;

  const matchOptions = useMemo(() => {
    return template
      .attribute_choices!.matches.filter(
        (option: MatchDetailed) => option.competition_id === competition?.id && option.season === season?.id
      )
      .map(
        (option: MatchDetailed) =>
          ({
            // Using == operator to check for nullish constraints while letting 0 through
            // eslint-disable-next-line eqeqeq
            label: `${option.home_team_name} - ${option.away_team_name}${!(option.home_team_score == null) ? ` (${option.home_team_score}:${option.away_team_score})` : ''}, ${option.competition_name}, ${option.season}, ${new Date(option.date).toLocaleDateString()}`,
            id: option.match_id
          }) as ValueOption
      );
  }, [competition?.id, season?.id, template.attribute_choices]);

  const seasonComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'seasons_comparison'
  }) as ValueOption | null;
  const competitionComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'competitions_comparison'
  }) as ValueOption | null;
  const matchComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'matches_comparison'
  }) as ValueOption | null;

  const matchComparisonOptions = useMemo(() => {
    return template
      .attribute_choices!.matches_comparison.filter(
        (option: MatchDetailed) =>
          option.competition_id === competitionComparison?.id && option.season === seasonComparison?.id
      )
      .map(
        (option: MatchDetailed) =>
          ({
            // Using == operator to check for nullish constraints while letting 0 through
            // eslint-disable-next-line eqeqeq
            label: `${option.home_team_name} - ${option.away_team_name}${!(option.home_team_score == null) ? ` (${option.home_team_score}:${option.away_team_score})` : ''}, ${option.competition_name}, ${option.season}, ${new Date(option.date).toLocaleDateString()}`,
            id: option.match_id
          }) as ValueOption
      );
  }, [competitionComparison?.id, seasonComparison?.id, template.attribute_choices]);

  return (
    <>
      <div className="flex flex-col gap-6">
        <span className="text-md font-semibold">{subject1}</span>
        <div className="flex gap-6">
          <SingleSelectInput
            name={'seasons'}
            label="Season"
            control={heatMapElementForm.control}
            options={template.attribute_choices!.seasons.map(
              (val: { season: string }) =>
                ({
                  label: val.season,
                  id: val.season
                }) as ValueOption
            )}
            rules={{
              required: 'Season is required!'
            }}
            error={formState.errors.seasons}
          />
          <SingleSelectInput
            name={'competitions'}
            label="Competition"
            control={heatMapElementForm.control}
            options={template.attribute_choices!.competitions.map(
              (val: Competition) =>
                ({
                  label: val.competition_name,
                  id: val.competition_id
                }) as ValueOption
            )}
            rules={{
              required: 'Competition is required!'
            }}
            error={formState.errors.competitions}
          />
        </div>
        <SingleSelectInput
          name={'matches'}
          label="Choose match"
          control={heatMapElementForm.control}
          options={matchOptions}
          rules={{
            required: 'Match is required!'
          }}
          disabled={!season || !competition}
          error={formState.errors.matches}
        />
        {template.attribute_choices!.players && (
          <MultipleSelectInput
            name={'players'}
            label="Players"
            placeholder="All"
            control={heatMapElementForm.control}
            options={template.attribute_choices!.players.map(
              (val: Player) =>
                ({
                  label: val.player_name,
                  id: val.player_id
                }) as ValueOption
            )}
            error={formState.errors.players}
          />
        )}
      </div>
      <div className="flex flex-col gap-6">
        <span className="text-md font-semibold">{subject2}</span>
        <div className="flex gap-6">
          <SingleSelectInput
            label="Season"
            name={'seasons_comparison'}
            control={heatMapElementForm.control}
            options={template.attribute_choices!.seasons_comparison.map(
              (val: { season: string }) =>
                ({
                  label: val.season,
                  id: val.season
                }) as ValueOption
            )}
            rules={{
              required: 'Comparison season is required!'
            }}
            error={formState.errors.seasons_comparison}
          />
          <SingleSelectInput
            label="Competition"
            name={'competitions_comparison'}
            control={heatMapElementForm.control}
            options={template.attribute_choices!.competitions_comparison.map(
              (val: Competition) =>
                ({
                  label: val.competition_name,
                  id: val.competition_id
                }) as ValueOption
            )}
            rules={{
              required: 'Comparison competition is required!'
            }}
            error={formState.errors.competitions_comparison}
          />
        </div>
        <SingleSelectInput
          label="Choose match"
          name={'matches_comparison'}
          control={heatMapElementForm.control}
          options={matchComparisonOptions}
          rules={{
            required: 'Comparison match is required!'
          }}
          error={formState.errors.matches_comparison}
          disabled={!seasonComparison || !competitionComparison}
        />
        {template.attribute_choices!.players_comparison && (
          <MultipleSelectInput
            name={'players_comparison'}
            label="Players"
            placeholder="All"
            control={heatMapElementForm.control}
            options={template.attribute_choices!.players_comparison.map(
              (val: Player) =>
                ({
                  label: val.player_name,
                  id: val.player_id
                }) as ValueOption
            )}
            error={formState.errors.players_comparison}
          />
        )}
      </div>
      <div className="flex flex-col gap-6">
        {/* <ButtonTabs
          name={'time_period'}
          control={heatMapElementForm.control}
          options={template.attribute_choices!.time_period.map(
            (val: string) =>
              ({
                label: snakeCaseToWords(val),
                id: val
              }) as ValueOption
          )}
          rules={{
            required: 'Time period is required!'
          }}
          error={formState.errors.time_period}
        /> */}
        <div className="grid grid-cols-2 gap-x-6 gap-y-3">
          <span className="text-md font-semibold">{subject1}</span>
          <span className="text-md font-semibold">{subject2}</span>
          {match && <span className="text-xs font-semibold">{match.label}</span>}
          {matchComparison && <span className="text-xs font-semibold">{matchComparison.label}</span>}
        </div>
        {/* <div className="grid grid-cols-2 gap-x-6 gap-y-3">
          <div className="grid grid-cols-2 gap-x-3">
            <SimpleSingleSelectInput
              name={'phase'}
              control={heatMapElementForm.control}
              options={template.attribute_choices!.phase.map(
                (val: string) =>
                  ({
                    label: snakeCaseToWords(val),
                    id: val
                  }) as ValueOption
              )}
              rules={{
                required: 'Phase is required!'
              }}
              error={formState.errors.phase}
            />
            <SimpleSingleSelectInput
              name={'pressure'}
              control={heatMapElementForm.control}
              options={template.attribute_choices!.pressure.map(
                (val: string) =>
                  ({
                    label: snakeCaseToWords(val),
                    id: val
                  }) as ValueOption
              )}
              rules={{
                required: 'Pressure is required!'
              }}
              error={formState.errors.pressure}
            />
          </div>
          <div className="grid grid-cols-2 gap-x-3">
            <SimpleSingleSelectInput
              name={'phase'}
              control={heatMapElementForm.control}
              options={template.attribute_choices!.phase.map(
                (val: string) =>
                  ({
                    label: snakeCaseToWords(val),
                    id: val
                  }) as ValueOption
              )}
              rules={{
                required: 'Phase is required!'
              }}
              error={formState.errors.phase}
            />
            <SimpleSingleSelectInput
              name={'pressure'}
              control={heatMapElementForm.control}
              options={template.attribute_choices!.pressure.map(
                (val: string) =>
                  ({
                    label: snakeCaseToWords(val),
                    id: val
                  }) as ValueOption
              )}
              rules={{
                required: 'Pressure is required!'
              }}
              error={formState.errors.pressure}
            />
          </div>
        </div> */}
      </div>
    </>
  );
});

export default ComparisonHeatMapElementForm;
