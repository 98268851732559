import { useQueryClient } from '@tanstack/react-query';
import { DefaultConfigurationFormValues } from 'constants/defaultValues';
import { dataSourceRefreshFrequencyOptions } from 'constants/formOptions';
import { CRONTimeOptions, seasonOptions } from 'constants/generated';
import { useObjectsOptions } from 'hooks/useOptions';
import {
  getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions,
  usePutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
} from 'lib/datasource-configuration/datasource-configuration';
import {
  DatasourceConfigurationCompetition,
  ProjectConfigurationCreateSchema,
  ProjectConfigurationNestedSchema,
  ProjectConfigurationSchema
} from 'lib/model';
import { getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions } from 'lib/project-configuration/project-configuration';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getMinimumStartSeasonFromDataSourceConfig, getSelectedLeaguesFromDataSourceConfig } from 'utils/helpers';
import { ConfigurationFormValues, DataSourceDialogProps } from 'utils/interfaces';

const EditDataSourceDialog = memo(function EditDataSourceDialog({
  open,
  setOpen,
  dataSource,
  dataSourceConfiguration
}: DataSourceDialogProps) {
  const { control, handleSubmit } = useForm<ConfigurationFormValues>({
    defaultValues: {
      leagues:
        getSelectedLeaguesFromDataSourceConfig(dataSourceConfiguration, dataSource) ??
        DefaultConfigurationFormValues.leagues,
      min_season:
        getMinimumStartSeasonFromDataSourceConfig(dataSourceConfiguration) ?? DefaultConfigurationFormValues.min_season,
      refreshFrequency:
        dataSourceRefreshFrequencyOptions.find(
          (x) => x.id === dataSourceConfiguration.frequency!.substring(4).trimStart()
        ) ?? DefaultConfigurationFormValues.refreshFrequency,
      time:
        CRONTimeOptions.find((x) => x.id === dataSourceConfiguration?.frequency!.substring(0, 4).trimEnd()) ??
        DefaultConfigurationFormValues.time
    }
  });
  const queryClient = useQueryClient();
  const projectConfiguration = queryClient.getQueryData<ProjectConfigurationSchema>(['projectConfiguration'])!;
  const { mutate: updateDataSource } =
    usePutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId();

  const leagueOptions = useObjectsOptions({ objects: dataSource.datasource_competitions });
  const [loading, setLoading] = useState(false);

  function editConfiguration(data: ConfigurationFormValues) {
    setLoading(true);
    const { stats: _, ...rest } = dataSourceConfiguration;
    updateDataSource(
      {
        projectConfigurationId: projectConfiguration.id!,
        datasourceConfigurationId: dataSourceConfiguration.id!,
        data: {
          ...rest,
          datasource_configuration_competitions: data.leagues.map(
            (x) =>
              ({
                datasource_competition_id: x.id,
                // all competitions have the same start season
                start_season: data.min_season?.id ?? undefined
              }) as DatasourceConfigurationCompetition
          ),
          datasource_configuration_options: dataSourceConfiguration.datasource_configuration_options!.map((x) => ({
            datasource_option_id: x.datasource_option_id,
            value: x.value
          })),
          datasource_configuration_endpoints: dataSourceConfiguration.datasource_configuration_endpoints!.map((x) => ({
            datasource_endpoint_id: x.datasource_endpoint_id
          })),
          frequency: data.time.id + ' ' + data.refreshFrequency.id
        }
      },
      {
        onSuccess: async (result: ProjectConfigurationCreateSchema) => {
          queryClient.removeQueries({ queryKey: ['projectConfiguration'] });
          queryClient.removeQueries({ queryKey: ['dataSourceConfigurations'] });
          await queryClient
            .fetchQuery(
              getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions(
                result.project!,
                result.id!,
                { nested: true },
                {
                  query: {
                    queryKey: ['projectConfiguration'],
                    staleTime: Infinity
                  }
                }
              )
            )
            .then((x: ProjectConfigurationNestedSchema) =>
              queryClient.fetchQuery(
                getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions(x.id!, {
                  query: { queryKey: ['dataSourceConfigurations'], staleTime: Infinity }
                })
              )
            );
          setLoading(false);
          setOpen(false);
          toast.success('Data source configuration saved successfully.');
        },
        onError: () => {
          setLoading(false);
        }
      }
    );
  }

  function handleCancel() {
    setOpen(false);
  }

  return (
    <DialogBase title="Edit Data Source" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex items-center bg-gray-50 p-3">
          <DataSourceTitle name={dataSource.name!} image={dataSource?.logo_image_path} />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-md font-semibold">Configure Data Source</span>
          <span className="text-sm">Updating your Data Source preferences may require some time to synchronize.</span>
          <span className="text-sm italic text-gray-400">
            Note: If you need to update your credentials, please remove and add a data source with new credentials.
          </span>
        </div>
        <form onSubmit={handleSubmit(editConfiguration)} id="edit-active-data-source" className="flex flex-col gap-6">
          <div className="flex flex-col gap-6">
            <div>
              <MultipleSelectInput
                control={control}
                name="leagues"
                placeholder="Select competitions"
                label="Competitions"
                options={leagueOptions}
              />
              <div className="mt-3 text-sm italic text-gray-400">
                Note: Currently it's possible to select at most 5 competitions. Your account may not have access to all
                of the listed competitions.
              </div>
            </div>

            <SingleSelectInput
              control={control}
              name="min_season"
              placeholder="Select earliest season"
              label="Earliest season"
              options={seasonOptions}
            />

            {/* NOTE: Refresh frequency needs to match all data sources so for now editing is disabled*/}
            <div>
              <div className="flex gap-6">
                <SingleSelectInput
                  control={control}
                  name="refreshFrequency"
                  placeholder="Default"
                  label="Refresh Frequency"
                  options={dataSourceRefreshFrequencyOptions}
                  disabled={true}
                />
                <SingleSelectInput
                  control={control}
                  name="time"
                  placeholder="Default"
                  label="Time"
                  options={CRONTimeOptions}
                  disabled={true}
                />
              </div>

              <div className="mt-3 text-sm italic text-gray-400">Note: All times are in UTC timezone.</div>
              <div className="mt-1 text-sm italic text-gray-400">
                Note: If you need to change refresh time, please remove all data sources and add them again.
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button isSubmitButton={true} size="lg" form="edit-active-data-source" loading={loading}>
          Save Updates
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default EditDataSourceDialog;
