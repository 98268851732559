/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
  default as getProjectProjectIdDataTableDataTableIdAutocompleteMutator,
  default as getProjectProjectIdDataTableDataTableIdDownloadMutator,
  default as getProjectProjectIdDataTableDataTableIdMutator
} from '../../api/axios';
import type {
  ClientDataSchema,
  GetProjectProjectIdDataTableDataTableIdAutocompleteParams,
  GetProjectProjectIdDataTableDataTableIdDownloadParams,
  GetProjectProjectIdDataTableDataTableIdParams
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Retrieve data from a given project the user has access to
 */
export const getProjectProjectIdDataTableDataTableId = (
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdParams,
  options?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDataTableDataTableIdMutator<ClientDataSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/data-table/${encodeURIComponent(String(dataTableId))}`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetProjectProjectIdDataTableDataTableIdQueryKey = (
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdParams
) => {
  return [`/project/${projectId}/data-table/${dataTableId}`, ...(params ? [params] : [])] as const;
};

export const getGetProjectProjectIdDataTableDataTableIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProjectProjectIdDataTableDataTableIdQueryKey(projectId, dataTableId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>> = ({ signal }) =>
    getProjectProjectIdDataTableDataTableId(projectId, dataTableId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && dataTableId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDataTableDataTableIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>
>;
export type GetProjectProjectIdDataTableDataTableIdQueryError = unknown;

/**
 * @summary Retrieve data from a given project the user has access to
 */
export const useGetProjectProjectIdDataTableDataTableId = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDataTableDataTableIdQueryOptions(projectId, dataTableId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Autocomplete data from a given project the user has access to
 */
export const getProjectProjectIdDataTableDataTableIdAutocomplete = (
  projectId: string,
  dataTableId: string,
  params: GetProjectProjectIdDataTableDataTableIdAutocompleteParams,
  options?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdAutocompleteMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDataTableDataTableIdAutocompleteMutator<string[]>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/data-table/${encodeURIComponent(String(dataTableId))}/autocomplete`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetProjectProjectIdDataTableDataTableIdAutocompleteQueryKey = (
  projectId: string,
  dataTableId: string,
  params: GetProjectProjectIdDataTableDataTableIdAutocompleteParams
) => {
  return [`/project/${projectId}/data-table/${dataTableId}/autocomplete`, ...(params ? [params] : [])] as const;
};

export const getGetProjectProjectIdDataTableDataTableIdAutocompleteQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdAutocomplete>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  params: GetProjectProjectIdDataTableDataTableIdAutocompleteParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdAutocomplete>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdAutocompleteMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectProjectIdDataTableDataTableIdAutocompleteQueryKey(projectId, dataTableId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdAutocomplete>>> = ({
    signal
  }) => getProjectProjectIdDataTableDataTableIdAutocomplete(projectId, dataTableId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && dataTableId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdAutocomplete>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDataTableDataTableIdAutocompleteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdAutocomplete>>
>;
export type GetProjectProjectIdDataTableDataTableIdAutocompleteQueryError = unknown;

/**
 * @summary Autocomplete data from a given project the user has access to
 */
export const useGetProjectProjectIdDataTableDataTableIdAutocomplete = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdAutocomplete>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  params: GetProjectProjectIdDataTableDataTableIdAutocompleteParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdAutocomplete>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdAutocompleteMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDataTableDataTableIdAutocompleteQueryOptions(
    projectId,
    dataTableId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Export data from a given project the user has access to
 */
export const getProjectProjectIdDataTableDataTableIdDownload = (
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdDownloadParams,
  options?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdDownloadMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDataTableDataTableIdDownloadMutator<string>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/data-table/${encodeURIComponent(String(dataTableId))}/download`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetProjectProjectIdDataTableDataTableIdDownloadQueryKey = (
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdDownloadParams
) => {
  return [`/project/${projectId}/data-table/${dataTableId}/download`, ...(params ? [params] : [])] as const;
};

export const getGetProjectProjectIdDataTableDataTableIdDownloadQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdDownload>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdDownload>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdDownloadMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectProjectIdDataTableDataTableIdDownloadQueryKey(projectId, dataTableId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdDownload>>> = ({
    signal
  }) => getProjectProjectIdDataTableDataTableIdDownload(projectId, dataTableId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && dataTableId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdDownload>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDataTableDataTableIdDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdDownload>>
>;
export type GetProjectProjectIdDataTableDataTableIdDownloadQueryError = unknown;

/**
 * @summary Export data from a given project the user has access to
 */
export const useGetProjectProjectIdDataTableDataTableIdDownload = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdDownload>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdDownload>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdDownloadMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDataTableDataTableIdDownloadQueryOptions(
    projectId,
    dataTableId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
