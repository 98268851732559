import { memo } from 'react';
import ElementRow from '../../elements/ElementRow';
import { PlayerGeneralData } from '../interfaces';
import PlayerHeader from './PlayerHeader';

interface PlayerElementProps {
  player: PlayerGeneralData;
}

const PlayerElement = memo(function PlayerElement({ player }: PlayerElementProps) {
  return (
    <div className="flex flex-col gap-6">
      <PlayerHeader player={player} textSize="lg" iconSize="md" />
      <div className="flex flex-col gap-2">
        <ElementRow>
          <span className="text-tiny uppercase">AGE</span>
          <span>{player.age ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">BIRTH DATE</span>
          <span>{player.birth_date ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">BIRTH PLACE</span>
          <span>{player.birthplace ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">HEIGHT</span>
          <span>{player.height ? `${player.height} cm` : '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">WEIGHT</span>
          <span>{player.weight ? `${player.weight} kg` : '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">POSITION</span>
          <span>{player.position_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">TEAM</span>
          <span>{player.team_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">NATIONALITY</span>
          <span>{player.country ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">VALUE</span>
          <span>{player.value ?? '-'}</span>
        </ElementRow>
      </div>
    </div>
  );
});

export default PlayerElement;
