import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { Player, ValueOption } from 'utils/interfaces';
import { AttributeValues } from '../../elements/interfaces';
import { attributeValuesOption, attributeValuesOptions, attributeValuesPlayers } from './helpers';
import { ElementFormProps, RadarChartElementFormValues } from './interfaces';

const RadarChartElementForm = memo(function RadarChartElementForm({
  template,
  report,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<RadarChartElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<RadarChartElementFormValues>;
    if (values.players) {
      defaultValues.players = attributeValuesPlayers(template.attribute_choices!.players, values.players as number[]);
    }
    if (values.attribute_type) {
      defaultValues.attribute_type = attributeValuesOption(values.attribute_type as string[]);
    }
    if (values.metrics_players) {
      defaultValues.metrics_players = attributeValuesOptions(values.metrics_players as string[]);
    }
    if (values.metrics_teams) {
      defaultValues.metrics_teams = attributeValuesOptions(values.metrics_teams as string[]);
    }
  } else {
    if (
      report.report_type === 'player' ||
      report.report_type === 'match' ||
      report.report_type === 'player_comparison' ||
      report.report_type === 'scout'
    ) {
      defaultValues.attribute_type = {
        label: 'Player',
        id: 'player'
      };
    } else if (report.report_type === 'team_comparison') {
      defaultValues.attribute_type = {
        label: 'Team',
        id: 'team'
      };
    }
  }
  const { control, watch, handleSubmit, setValue, getValues, formState } = useForm<RadarChartElementFormValues>({
    defaultValues
  });
  const attributeType = watch('attribute_type');

  const addElement = useCallback(
    function addElement(data: RadarChartElementFormValues) {
      if (template!.attribute_choices!.players) {
        const players = getValues('players');
        if (attributeType?.id === 'player' && players.length === 0) {
          data.players = template.attribute_choices!.players.map(
            (player: Player) =>
              ({
                label: player.player_name,
                id: player.player_id
              }) as ValueOption
          );
        }
      }

      onSubmitForm(data);
    },
    [attributeType?.id, getValues, onSubmitForm, template]
  );

  useEffect(() => {
    if (attributeType?.id === 'team') {
      setValue('players', []);
    }
  }, [attributeType?.id, setValue]);

  const attributeTypeOptions = useMemo(
    () =>
      template.attribute_choices!.attribute_type?.map(
        (option: string) =>
          ({
            label: snakeCaseToWords(option),
            id: option
          }) as ValueOption
      ) ?? [],
    [template.attribute_choices]
  );

  const metricsPlayersOptions = useMemo(
    () =>
      template.attribute_choices!.metrics_players?.map(
        (option: string) =>
          ({
            label: snakeCaseToWords(option),
            id: option
          }) as ValueOption
      ) ?? [],
    [template.attribute_choices]
  );

  const metricsTeamsOptions = useMemo(
    () =>
      template.attribute_choices!.metrics_teams?.map(
        (option: string) =>
          ({
            label: snakeCaseToWords(option),
            id: option
          }) as ValueOption
      ) ?? [],
    [template.attribute_choices]
  );

  const playerOptions = useMemo(
    () =>
      template.attribute_choices!.players?.map(
        (player: Player) =>
          ({
            label: player.player_name,
            id: player.player_id
          }) as ValueOption
      ) ?? [],
    [template.attribute_choices]
  );

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      {template.attribute_choices!.attribute_type && (
        <SingleSelectInput
          name={'attribute_type'}
          control={control}
          label={'Attribute type'}
          options={attributeTypeOptions}
          disabled={attributeTypeOptions.length === 1}
          rules={{ required: 'Attribute type is required!' }}
          error={formState.errors.attribute_type}
        />
      )}
      {attributeType?.id === 'player' && template.attribute_choices!.players && (
        <MultipleSelectInput
          name={'players'}
          control={control}
          label={'Players'}
          placeholder="All"
          options={playerOptions}
        />
      )}
      {attributeType?.id === 'player' && template.attribute_choices!.metrics_players && (
        <MultipleSelectInput
          name={'metrics_players'}
          control={control}
          label={'Choose metrics (min 3 - max 12)'}
          options={metricsPlayersOptions}
          rules={{
            validate: {
              minLength: (values: ValueOption[]) => values.length >= 3 || 'At least three metrics are required!',
              maxLength: (values: ValueOption[]) => values.length <= 12 || 'At most twelve metrics can be selected!'
            }
          }}
          error={formState.errors.metrics_players}
        />
      )}
      {attributeType?.id === 'team' && template.attribute_choices!.metrics_teams && (
        <MultipleSelectInput
          name={'metrics_teams'}
          control={control}
          label={'Choose metrics (min 3 - max 12)'}
          placeholder="All"
          options={metricsTeamsOptions}
          rules={{
            validate: {
              minLength: (values: ValueOption[]) => values.length >= 3 || 'At least three metrics are required!',
              maxLength: (values: ValueOption[]) => values.length <= 12 || 'At most twelve metrics can be selected!'
            }
          }}
          error={formState.errors.metrics_teams}
        />
      )}
    </form>
  );
});

export default RadarChartElementForm;
