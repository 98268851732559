import { ColumnDef, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import GenericDisplayTable from 'modules/common/GenericDisplayTable';
import { memo, useMemo } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { getReportGeneralColor } from '../../../../helpers';
import Player from '../Player';
import Team from '../Team';

interface TableElementData {
  [key: string]: number | string | undefined;

  player_id: number;
  player_name: string;
  shirt_number: number;
  team_id: number;
  team_name: string;
  // group by fields
  season?: string;
  competition_id?: number;
  competition_name?: string;
  match_id?: number;
  home_team_name?: string;
  away_team_name?: string;
}

interface TableElementResult {
  result: TableElementData[];
}

interface TableElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const columnHelper = createColumnHelper<TableElementData>();

const TableElement = memo(function TableElement({ report, element }: TableElementProps) {
  const { result: data } = element.entity_data as TableElementResult;

  const columns = useMemo<ColumnDef<TableElementData>[]>(() => {
    const isPlayer: boolean = element.attribute_values!.attribute_type[0] === 'player';
    const metrics: string[] = isPlayer
      ? element.attribute_values!.metrics_players
      : element.attribute_values!.metrics_teams;
    const groupedBy: string[] = element.attribute_values!.group_by ?? [];

    let visualColumn;
    if (isPlayer) {
      visualColumn = columnHelper.accessor('visual', {
        id: 'visual',
        cell: (info) => {
          const { original } = info.row;
          return (
            <Player
              color={getReportGeneralColor(report, original.team_id, undefined, original.player_id)}
              size="md"
              player_name={original.player_name}
              shirt_number={original.shirt_number}
            />
          );
        },
        header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">PLAYER</span>
      });
    } else {
      visualColumn = columnHelper.accessor('player', {
        id: 'player',
        cell: (info) => {
          const { original } = info.row;
          return (
            <Team color={getReportGeneralColor(report, original.team_id)} size="md" team_name={original.team_name} />
          );
        },
        header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">PLAYER</span>
      });
    }

    const groupAttributeColumns: ColumnDef<TableElementData>[] = [];
    if (groupedBy.includes('season')) {
      groupAttributeColumns.push(
        columnHelper.accessor('season', {
          id: 'season',
          cell: (info) => <span className="px-3 text-xs font-medium">{info.renderValue()}</span>,
          header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">SEASON</span>,
          meta: {
            align: 'center'
          }
        })
      );
    }
    if (groupedBy.includes('competition')) {
      groupAttributeColumns.push(
        columnHelper.accessor('competition_name', {
          id: 'competition_name',
          cell: (info) => (
            <span className="px-3 text-xs font-medium">{snakeCaseToWords(info.renderValue() as string)}</span>
          ),
          header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">COMPETITION</span>
        })
      );
    }
    if (groupedBy.includes('match')) {
      groupAttributeColumns.push(
        columnHelper.accessor('match_id', {
          id: 'match_id',
          cell: (info) => {
            const original = info.row.original;
            const name = `${original.home_team_name} vs ${original.away_team_name}`;
            return <span className="px-3 text-xs font-medium">{name}</span>;
          },
          header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">MATCH</span>
        })
      );
    }

    return [
      visualColumn,
      ...groupAttributeColumns,
      ...metrics.map((metric) => {
        return columnHelper.accessor(metric, {
          id: metric,
          cell: (info) => {
            let value = info.renderValue();
            if (typeof value === 'number' && value % 1 !== 0) {
              value = value.toFixed(2);
            }
            return <span className="px-3 text-xs font-medium">{value}</span>;
          },
          header: () => (
            <span className="px-3 text-tiny font-medium uppercase text-gray-500">{snakeCaseToWords(metric)}</span>
          ),
          meta: {
            align: String(data[0][metric]).length > 8 ? 'left' : 'center'
          }
        });
      })
    ];
  }, [data, element.attribute_values, report]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    renderFallbackValue: '-'
  });

  return (
    <div className="flex flex-col gap-6">
      <GenericDisplayTable table={table} />
    </div>
  );
});

export default TableElement;
