import { ColumnDef, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { memo, useMemo } from 'react';
import { twJoin } from 'tailwind-merge';
import { snakeCaseToWords } from 'utils/helpers';

import { ScoutPlayerData } from '../interfaces';
import PlayerHeader from '../Player/PlayerHeader';

interface ScoutElementProps {
  manualPlayers: ScoutPlayerData[];
  filteredPlayers: ScoutPlayerData[];
}

const columnHelper = createColumnHelper<ScoutPlayerData>();
const excludedColumns = ['player_id', 'player_name', 'shirt_number', 'color', 'avg_rating', 'team_id'];
const staticColumns = [
  columnHelper.display({
    id: 'player',
    header: (info) => <span className="mx-3 text-xs font-semibold">Players: {info.table.getRowCount()}</span>,
    cell: (info) => {
      const { original, index } = info.row;
      return (
        <div className="bg-white px-1.5 py-1">
          <PlayerHeader player={original} index={index} iconSize="md" textSize="sm" narrower />
        </div>
      );
    }
  })
  // columnHelper.accessor('rating', {
  //   cell: (info) => (
  //     <span className="flex h-4 items-center gap-2">
  //       <Rating rating={info.getValue()} color="#49339E" />
  //       <span className="mx-3 text-tiny font-semibold ">{info.renderValue()}</span>
  //     </span>
  //   ),
  //   header: () => <span className="whitespace-nowrap px-3 text-tiny font-medium uppercase text-gray-500">RATING</span>
  // })
];

function renderDynamicColumn(key: keyof ScoutPlayerData) {
  switch (key) {
    case 'weight':
      return columnHelper.accessor(key, {
        cell: (info) => (
          <div className="bg-white px-1.5 py-1">
            <span className="flex size-full min-h-10 items-center justify-center rounded-md bg-gray-50 px-3 py-1.5 text-xs font-medium">
              {!!info.getValue() ? `${info.renderValue()} kg` : info.renderValue()}
            </span>
          </div>
        ),
        header: () => <span className="whitespace-nowrap px-3 text-tiny uppercase">WEIGHT</span>
      });
    case 'height':
      return columnHelper.accessor(key, {
        cell: (info) => (
          <div className="bg-white px-1.5 py-1">
            <span className="flex size-full min-h-10 items-center justify-center rounded-md bg-gray-50 px-3 py-1.5 text-xs font-medium">
              {!!info.getValue() ? `${info.renderValue()} cm` : info.renderValue()}
            </span>
          </div>
        ),
        header: () => <span className="whitespace-nowrap px-3 text-tiny uppercase">HEIGHT</span>
      });
    default:
      return columnHelper.accessor(key, {
        cell: (info) => (
          <div className="bg-white px-1.5 py-1">
            <span className="flex size-full min-h-10 items-center justify-center rounded-md bg-gray-50 px-3 py-1.5 text-xs font-medium">
              {info.renderValue()}
            </span>
          </div>
        ),
        header: () => <span className="whitespace-nowrap px-3 text-tiny uppercase">{snakeCaseToWords(key)}</span>
      });
  }
}

const ScoutElement = memo(function ScoutElement({ filteredPlayers, manualPlayers }: ScoutElementProps) {
  const combinedPlayers = useMemo(() => [...filteredPlayers, ...manualPlayers], [filteredPlayers, manualPlayers]);
  const dataColumns = useMemo<ColumnDef<ScoutPlayerData>[]>(() => {
    const columns = Object.keys(combinedPlayers[0]).filter((key) => !excludedColumns.includes(key)) ?? [];
    return columns.map((key: keyof ScoutPlayerData) => renderDynamicColumn(key));
  }, [combinedPlayers]);
  const columns = useMemo(() => [...staticColumns, ...dataColumns], [dataColumns]);

  const table = useReactTable({
    data: combinedPlayers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    renderFallbackValue: '-'
  });

  return (
    <div className="grid max-h-[calc(100vh-250px)] grid-cols-cards overflow-auto">
      <table className="relative w-full border-collapse border-none">
        <tbody>
          {table.getHeaderGroups().flatMap((headerGroup) => {
            return headerGroup.headers.flatMap((header) => {
              return (
                <tr className={twJoin('bg-white', header.column.id === 'player' && 'sticky top-0 z-[5]')}>
                  <th key={header.id} colSpan={header.colSpan} className="sticky left-0 z-[4] bg-white text-left">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                  {table.getRowModel().rows.map((row) => {
                    return row.getVisibleCells().map((cell, index) => {
                      if (cell.column.id !== header.column.id) return null;
                      return (
                        <td className="whitespace-nowrap" key={cell.id + index}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    });
                  })}
                </tr>
              );
            });
          })}
        </tbody>
      </table>
    </div>
  );
});

export default ScoutElement;
