export const newProjectConfigDefault = {
  region: 'eu-west-1',
  database_instance_type: 'db.t3.micro',
  database_engine: 'postgres',
  database_allocated_storage: 20,
  database_engine_version: '',
  datasource_configurations: [],
  project_configuration_metrics: [],
  project_configuration_data_tables: [],
  project_configuration_options: []
};
