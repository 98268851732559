import { useQueryClient } from '@tanstack/react-query';
import { ProjectRoleSchemaName } from 'lib/model';
import { useGetUser } from 'lib/user-account/user-account';
import { PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './authContext';

export function AuthProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: user } = useGetUser({
    query: { queryKey: ['user'], staleTime: Infinity, refetchInterval: 1000 * 60 * 30 }
  });

  const value = useMemo(() => {
    const isViewer =
      user?.user_projects![0].project_role_name === ProjectRoleSchemaName.viewer ||
      user?.user_projects![0].project_role_name === ProjectRoleSchemaName.scout_viewer;

    const isOwner = user?.user_projects![0].project_role_name === ProjectRoleSchemaName.owner;
    const isAdmin = user?.user_projects![0].project_role_name === ProjectRoleSchemaName.admin;

    function logout() {
      if (localStorage.getItem('inviteUsersState') === 'skipped') {
        localStorage.removeItem('inviteUsersState');
      }
      localStorage.removeItem('token');
      queryClient.clear();
      navigate('/login');
    }

    return {
      logout,
      user,
      isViewer,
      isOwner,
      isAdmin
    } as AuthContext;
  }, [navigate, queryClient, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
