import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { Player, ValueOption } from 'utils/interfaces';
import { AttributeValues } from '../../elements/interfaces';
import { attributeValuesOption, attributeValuesOptions, attributeValuesPlayers } from './helpers';
import { ComparisonElementFormValues, ElementFormProps } from './interfaces';

const ComparisonElementForm = memo(function ComparisonElementForm({
  template,
  report,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<ComparisonElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<ComparisonElementFormValues>;
    if (values.players) {
      defaultValues.players = attributeValuesPlayers(template.attribute_choices!.players, values.players as number[]);
    }
    if (values.attribute_type) {
      defaultValues.attribute_type = attributeValuesOption(values.attribute_type as string[]);
    }
    if (values.metrics_players) {
      defaultValues.metrics_players = attributeValuesOptions(values.metrics_players as string[]);
    }
    if (values.metrics_teams) {
      defaultValues.metrics_teams = attributeValuesOptions(values.metrics_teams as string[]);
    }
  } else {
    if (report.report_type === 'team_comparison') {
      defaultValues.attribute_type = {
        label: 'Team',
        id: 'team'
      };
    } else if (report.report_type !== 'match') {
      defaultValues.attribute_type = {
        label: 'Player',
        id: 'player'
      };
    }
  }

  const { handleSubmit, getValues, watch, setValue, control, formState } = useForm<ComparisonElementFormValues>({
    defaultValues
  });
  const attributeType = watch('attribute_type');

  const addElement = useCallback(
    function addElement(data: ComparisonElementFormValues) {
      if (template.attribute_choices!.players) {
        const players = getValues('players');
        if (attributeType?.id === 'player' && players.length === 0) {
          data.players = template.attribute_choices!.players.map(
            (player: Player) =>
              ({
                label: player.player_name,
                id: player.player_id
              }) as ValueOption
          );
        }
      }
      onSubmitForm(data);
    },
    [attributeType?.id, getValues, onSubmitForm, template.attribute_choices]
  );

  useEffect(() => {
    if (attributeType?.id === 'team') {
      setValue('players', []);
      setValue('metrics_players', []);
    } else {
      setValue('metrics_teams', []);
    }
  }, [attributeType, setValue]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <SingleSelectInput
        name={'attribute_type'}
        control={control}
        label={'Main attribute'}
        disabled={report.report_type !== 'match' && report.report_type !== 'team_comparison'}
        options={template.attribute_choices!.attribute_type.map(
          (option: string) =>
            ({
              label: snakeCaseToWords(option),
              id: option
            }) as ValueOption
        )}
        rules={{ required: 'Main attribute is required!' }}
        error={formState.errors.attribute_type}
      />
      {attributeType?.id === 'player' && template.attribute_choices!.players && (
        <MultipleSelectInput
          name={'players'}
          control={control}
          label={'Players'}
          placeholder="All"
          options={template.attribute_choices!.players.map(
            (player: Player) =>
              ({
                label: player.player_name,
                id: player.player_id
              }) as ValueOption
          )}
        />
      )}
      {attributeType?.id === 'player' ? (
        <MultipleSelectInput
          key={'metrics_players'}
          name="metrics_players"
          control={control}
          label={'Choose metrics to aggregate'}
          disabled={attributeType === null}
          options={template.attribute_choices!.metrics_players.map(
            (option: string) =>
              ({
                label: snakeCaseToWords(option),
                id: option
              }) as ValueOption
          )}
          rules={{
            validate: {
              minLength: (values: ValueOption[]) => values.length > 0 || 'At least one metric is required!'
            }
          }}
          error={formState.errors.metrics_players}
        />
      ) : (
        <MultipleSelectInput
          key={'metrics_teams'}
          name="metrics_teams"
          control={control}
          label={'Choose metrics to aggregate'}
          disabled={attributeType === null}
          options={template.attribute_choices!.metrics_teams.map(
            (option: string) =>
              ({
                label: snakeCaseToWords(option),
                id: option
              }) as ValueOption
          )}
          rules={{
            validate: {
              minLength: (values: ValueOption[]) => values.length > 0 || 'At least one metric is required!'
            }
          }}
          error={formState.errors.metrics_teams}
        />
      )}
    </form>
  );
});

export default ComparisonElementForm;
