import { QueryClient } from '@tanstack/react-query';
import { getGetProjectDataTableQueryOptions } from 'lib/data-table/data-table';
import { getGetDatasourceDatasourceIdQueryOptions } from 'lib/datasource/datasource';
import { LoaderFunctionArgs } from 'react-router-dom';

export const dataSourceDetailsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs<string>) => {
    const queryOptions = getGetDatasourceDatasourceIdQueryOptions(params.dataSourceId!, {
      query: {
        queryKey: ['dataSourcesById', params.dataSourceId],
        staleTime: Infinity
      }
    });
    return queryClient.ensureQueryData(queryOptions);
  };

export const dataTablesLoader = (queryClient: QueryClient) => async () => {
  const queryOptions = getGetProjectDataTableQueryOptions({
    query: {
      queryKey: ['dataTables'],
      staleTime: Infinity
    }
  });
  return queryClient.ensureQueryData(queryOptions);
};
