import { useQueryClient } from '@tanstack/react-query';
import Add from 'assets/plus.svg?react';
import { PlayerQuerySchema, ProjectSchema } from 'lib/model';
import { useGetReportGetPlayers } from 'lib/report/report';
import Button from 'modules/common/Button';
import Combobox from 'modules/common/Form/AsyncCombobox';
import Fieldset from 'modules/common/Form/Fieldset';
import { memo, useCallback, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { conditionallyRender } from 'utils/helpers';
import { AutocompleteOption, ValueOption } from 'utils/interfaces';
import { ScoutReportFormValues } from './interfaces';

interface AddManualPlayerFormValues {
  player: AutocompleteOption | null;
}

interface ScoutReportAddManualPlayerProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  isAddingPlayer: boolean;
  setIsAddingPlayer: React.Dispatch<React.SetStateAction<boolean>>;
  position: ValueOption | null;
  totalPlayers: number;
  currentPlayers: PlayerQuerySchema[];
}

function ScoutReportAddManualPlayer({
  scoutReportForm,
  currentPlayers,
  isAddingPlayer,
  setIsAddingPlayer,
  position,
  totalPlayers
}: ScoutReportAddManualPlayerProps) {
  const addPlayerForm = useForm<AddManualPlayerFormValues>({
    defaultValues: {
      player: null
    }
  });

  const [query, setQuery] = useState<string>('');
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const { data: players, isFetching: isFetchingPlayers } = useGetReportGetPlayers(
    {
      player_name: query,
      project_id: project.id,
      player_position: position?.id as string | undefined
    },
    {
      query: {
        queryKey: ['players', project.id, query, position?.id],
        staleTime: 1000 * 60 * 5,
        enabled: query.length > 0 && !!position
      }
    }
  );

  const playerOptions = useMemo(() => {
    return (
      players?.players
        ?.filter((player) => !currentPlayers.some((curr) => curr.player_id === player.player_id))
        .map(
          (player) =>
            ({
              id: player.player_id,
              label: `${player.player_name}${conditionallyRender(!!player.shirt_number, player.shirt_number)}${conditionallyRender(!!player.team_name, `| ${player.team_name}`)}`,
              secondaryLabel: `${new Date(player.birth_date!).toLocaleDateString()}`
            }) as AutocompleteOption
        ) ?? []
    );
  }, [currentPlayers, players?.players]);

  const toggleAddPlayer = useCallback(
    function toggleAddPlayer() {
      setIsAddingPlayer((state) => !state);
    },
    [setIsAddingPlayer]
  );

  const addManualPlayer = useCallback(
    function addManualPlayer(data: AddManualPlayerFormValues) {
      const playersOld = scoutReportForm.getValues('players');
      const player = players!.players!.find((p) => p.player_id === data.player?.id)!;
      scoutReportForm.setValue('players', [...playersOld, player]);
      setIsAddingPlayer((state) => !state);
      addPlayerForm.reset();
    },
    [addPlayerForm, players, scoutReportForm, setIsAddingPlayer]
  );

  const playerDescription = useMemo(
    () => (
      <div className="flex items-center gap-2">
        <span className="text-sm text-gray-500">Player position:</span>
        <span className="text-sm font-medium">{position?.label}</span>
      </div>
    ),
    [position]
  );

  return !isAddingPlayer ? (
    <Button variant="secondary" onClick={toggleAddPlayer} disabled={totalPlayers >= 20}>
      <Add width={20} height={20} />
      <span>Add player</span>
    </Button>
  ) : (
    <form id="scout-report-add-player-form" onSubmit={addPlayerForm.handleSubmit(addManualPlayer)}>
      <Fieldset legend="Add Player" description={playerDescription}>
        <Combobox
          error={addPlayerForm.formState.errors.player}
          loading={isFetchingPlayers}
          setQuery={setQuery}
          name="player"
          control={addPlayerForm.control}
          label="Player"
          placeholder="Start typing: Player name"
          options={playerOptions}
          rules={{ required: { value: true, message: 'Player is required.' } }}
        />
        <div className="ml-auto mt-4 flex w-1/2 items-center gap-4">
          <Button variant="secondary" size="lg" onClick={toggleAddPlayer}>
            <span>Cancel</span>
          </Button>
          <Button
            size="lg"
            isSubmitButton
            form="scout-report-add-player-form"
            disabled={!addPlayerForm.watch('player')}
          >
            <span>Add player</span>
          </Button>
        </div>
      </Fieldset>
    </form>
  );
}

export default memo(ScoutReportAddManualPlayer);
