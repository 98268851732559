import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';
import { AttributeValues } from '../../elements/interfaces';
import { attributeValuesOption, attributeValuesOptions } from './helpers';
import { ElementFormProps, ElementFormValues } from './interfaces';

interface TableElementFormValues extends ElementFormValues {
  attribute_type: ValueOption | null;
  metrics_players: ValueOption[];
  metrics_teams: ValueOption[];
  group_by: ValueOption[];
}

const TableElementForm = memo(function TableElementForm({ template, report, onSubmitForm, element }: ElementFormProps) {
  const defaultValues: Partial<TableElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<TableElementFormValues>;
    if (values.attribute_type) {
      defaultValues.attribute_type = attributeValuesOption(values.attribute_type as string[]);
    }
    if (values.metrics_players) {
      defaultValues.metrics_players = attributeValuesOptions(values.metrics_players as string[]);
    }
    if (values.metrics_teams) {
      defaultValues.metrics_teams = attributeValuesOptions(values.metrics_teams as string[]);
    }
    if (values.group_by) {
      defaultValues.group_by = attributeValuesOptions(values.group_by as string[]);
    }
  } else {
    if (
      report.report_type === 'player' ||
      report.report_type === 'player_comparison' ||
      report.report_type === 'scout'
    ) {
      defaultValues.attribute_type = {
        label: 'Player',
        id: 'player'
      };
    } else if (report.report_type === 'team' || report.report_type === 'team_comparison') {
      defaultValues.attribute_type = {
        label: 'Team',
        id: 'team'
      };
    }
  }

  const { watch, setValue, control, formState, handleSubmit } = useForm<TableElementFormValues>({
    defaultValues
  });
  const attributeType = watch('attribute_type');

  const addElement = useCallback(
    function addElement(data: TableElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  useEffect(() => {
    if (attributeType?.id === 'team') {
      setValue('metrics_players', []);
    } else {
      setValue('metrics_teams', []);
    }
  }, [attributeType, setValue]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      {report.report_type === 'match' && (
        <SingleSelectInput
          name={'attribute_type'}
          control={control}
          label={'Main attribute'}
          options={template.attribute_choices!.attribute_type.map(
            (option: string) =>
              ({
                label: snakeCaseToWords(option),
                id: option
              }) as ValueOption
          )}
          rules={{ required: 'Main attribute is required!' }}
          error={formState.errors.attribute_type}
        />
      )}
      {report.report_type !== 'match' && (
        <div className="flex gap-6">
          <SingleSelectInput
            disabled={false}
            name={'attribute_type'}
            control={control}
            label={'Main attribute'}
            options={template.attribute_choices!.attribute_type.map(
              (option: string) =>
                ({
                  label: snakeCaseToWords(option),
                  id: option
                }) as ValueOption
            )}
            rules={{ required: 'Main attribute is required!' }}
            error={formState.errors.attribute_type}
          />
          <MultipleSelectInput
            name={'group_by'}
            control={control}
            label={'Choose other attributes (max 3)'}
            options={template.attribute_choices!.group_by.map(
              (option: string) =>
                ({
                  label: snakeCaseToWords(option),
                  id: option
                }) as ValueOption
            )}
            rules={{
              validate: {
                minLength: (values: ValueOption[]) => values.length > 0 || 'At least one attribute is required!',
                maxLength: (values: ValueOption[]) =>
                  values.length <= 3 || 'Maximum of three attributes can be selected!'
              }
            }}
            error={formState.errors.group_by}
          />
        </div>
      )}
      {attributeType?.id === 'player' ? (
        <MultipleSelectInput
          key={'metrics_players'}
          name="metrics_players"
          control={control}
          label={'Choose metrics to aggregate'}
          disabled={attributeType === null}
          options={template.attribute_choices!.metrics_players.map(
            (option: string) =>
              ({
                label: snakeCaseToWords(option),
                id: option
              }) as ValueOption
          )}
          rules={{
            validate: {
              minLength: (values: ValueOption[]) => values.length > 0 || 'At least one metric is required!'
            }
          }}
          error={formState.errors.metrics_players}
        />
      ) : (
        <MultipleSelectInput
          key={'metrics_teams'}
          name="metrics_teams"
          control={control}
          label={'Choose metrics to aggregate'}
          disabled={attributeType === null}
          options={template.attribute_choices!.metrics_teams.map(
            (option: string) =>
              ({
                label: snakeCaseToWords(option),
                id: option
              }) as ValueOption
          )}
          rules={{
            validate: {
              minLength: (values: ValueOption[]) => values.length > 0 || 'At least one metric is required!'
            }
          }}
          error={formState.errors.metrics_teams}
        />
      )}
    </form>
  );
});

export default TableElementForm;
