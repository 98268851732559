import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ReportElementTemplatesSchema, ReportNestedSchema } from 'lib/model';
import { useDeleteReportReportIdElementElementId } from 'lib/report/report';
import { memo, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { toast } from 'react-toastify';
import ElementCardContent from './ElementCardContent';
import ElementCardFallback from './ElementCardFallback';

interface ElementCardProps {
  report: ReportNestedSchema;
  elementTemplates?: ReportElementTemplatesSchema;
  elementId: string;
  first: boolean;
  last: boolean;
}

const ElementCard = memo(function ElementCard(props: ElementCardProps) {
  const queryClient = useQueryClient();
  const { mutate: deleteReportElement } = useDeleteReportReportIdElementElementId({
    mutation: {
      onMutate: async (body) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        await queryClient.cancelQueries({ queryKey: ['reports', props.report.id!] });
        const previous = queryClient.getQueryData<ReportNestedSchema>(['reports', props.report.id!]);
        queryClient.setQueryData<ReportNestedSchema>(['reports', props.report.id!], (old) => {
          if (!old) return previous;
          return {
            ...old,
            report_elements: old.report_elements!.filter((el) => el !== body.elementId)
          };
        });
        return { previous, loadingToastId };
      },
      onError: (err, body, context) => {
        toast.dismiss(context?.loadingToastId);
        if (err instanceof AxiosError) {
          toast.error(err.response?.data.error);
        }
        toast.error('Your have unsaved changes');
        queryClient.setQueryData<ReportNestedSchema>(['reports', props.report.id!], context?.previous);
      },
      onSuccess: (err, body, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.success('Your changes have been saved');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          exact: true,
          queryKey: ['reports', props.report.id!]
        });
        queryClient.removeQueries({
          exact: true,
          queryKey: ['reports', props.report.id, 'elements', props.elementId]
        });
      }
    }
  });

  const handleRemove = useCallback(
    function handleRemove() {
      deleteReportElement({
        reportId: props.report.id!,
        elementId: props.elementId
      });
    },
    [deleteReportElement, props.report.id, props.elementId]
  );

  return (
    <ErrorBoundary fallback={<ElementCardFallback onRemove={handleRemove} />}>
      <ElementCardContent {...props} />
    </ErrorBoundary>
  );
});

export default ElementCard;
