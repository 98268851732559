import { ReportElementTemplateSchemaAttributeChoices } from 'lib/model';
import { ValueOption } from 'utils/interfaces';
import { ElementFormValues } from './elementForms/interfaces';

export function transformAttributeValues(
  data: ElementFormValues,
  attributeChoices?: ReportElementTemplateSchemaAttributeChoices
) {
  const attributeValues: Record<string, string[] | number[] | boolean[] | undefined> = {};
  for (const [key, value] of Object.entries(data)) {
    if (!attributeChoices?.[key]) {
      continue;
    }

    let attributeValue: string[] | number[] | boolean[] | undefined;
    if (typeof value === 'boolean') {
      attributeValue = [value];
    } else if (!!value && Array.isArray(value)) {
      attributeValue = (value as ValueOption[]).map((option) => option.value ?? option.id) as number[] | string[];
    } else if (!!value) {
      attributeValue = [value.value ?? value.id] as number[] | string[];
    }
    if (attributeValue) {
      attributeValues[key] = attributeValue;
    }
  }

  return attributeValues;
}
