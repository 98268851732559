import { memo } from 'react';
import ElementComparisonRow from '../ElementComparisonRow';
import PlayerComparisonHeader from './PlayerComparisonHeader';

import { PlayerComparisonGeneralData } from '../interfaces';

const PlayerComparisonElement = memo(function PlayerComparisonElement({
  player1,
  player2
}: PlayerComparisonGeneralData) {
  return (
    <div className="flex flex-col gap-6">
      <PlayerComparisonHeader player1={player1} player2={player2} textSize="sm" iconSize="md" />
      <div className="flex flex-col gap-2">
        <ElementComparisonRow>
          <span>{player1.age ?? '-'}</span>
          <span className="text-tiny uppercase">AGE</span>
          <span>{player2.age ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.birth_date ?? '-'}</span>
          <span className="text-tiny uppercase">BIRTH DATE</span>
          <span>{player2.birth_date ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.birthplace ?? '-'}</span>
          <span className="text-tiny uppercase">BIRTH PLACE</span>
          <span>{player2.birthplace ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.height ?? '-'}</span>
          <span className="text-tiny uppercase">HEIGHT</span>
          <span>{player2.height ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.weight ?? '-'}</span>
          <span className="text-tiny uppercase">WEIGHT</span>
          <span>{player2.weight ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.position_name ?? '-'}</span>
          <span className="text-tiny uppercase">POSITION</span>
          <span>{player2.position_name ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.team_name ?? '-'}</span>
          <span className="text-tiny uppercase">TEAM</span>
          <span>{player2.team_name ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.country ?? '-'}</span>
          <span className="text-tiny uppercase">NATIONALITY</span>
          <span>{player2.country ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.value ?? '-'}</span>
          <span className="text-tiny uppercase">VALUE</span>
          <span>{player2.value ?? '-'}</span>
        </ElementComparisonRow>
      </div>
    </div>
  );
});

export default PlayerComparisonElement;
