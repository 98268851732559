import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { Competition, Match, ValueOption } from 'utils/interfaces';
import { AttributeValues } from '../../elements/interfaces';
import {
  attributeValuesCompetitions,
  attributeValuesMatch,
  attributeValuesOption,
  attributeValuesOptions
} from './helpers';
import { ElementFormProps, LineChartElementFormValues } from './interfaces';

const LineChartElementForm = memo(function LineChartElementForm({
  template,
  report,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<LineChartElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<LineChartElementFormValues>;
    if (values.type) {
      defaultValues.type = attributeValuesOption(values.type as string[]);
    }
    if (values.competitions) {
      defaultValues.competitions = attributeValuesCompetitions(
        template.attribute_choices!.competitions,
        values.competitions as number[]
      );
    }
    if (values.seasons) {
      defaultValues.seasons = attributeValuesOptions(
        values.seasons as string[],
        true,
        template.attribute_choices!.seasons
      );
    }
    if (values.match_id) {
      defaultValues.match_id = attributeValuesMatch(template.attribute_choices!.match_id, values.match_id as number[]);
    }
    if (values.metrics_within_match) {
      defaultValues.metrics_within_match = attributeValuesOption(values.metrics_within_match as string[]);
    }
    if (values.metrics_within_season) {
      defaultValues.metrics_within_season = attributeValuesOption(values.metrics_within_season as string[]);
    }
    if (values.time_scale) {
      defaultValues.time_scale = attributeValuesOption(values.time_scale as string[]);
    }
    if (values.match_id_player1) {
      defaultValues.match_id_player1 = attributeValuesMatch(
        template.attribute_choices!.match_id_player1,
        values.match_id_player1 as number[]
      );
    }
    if (values.match_id_player2) {
      defaultValues.match_id_player2 = attributeValuesMatch(
        template.attribute_choices!.match_id_player2,
        values.match_id_player2 as number[]
      );
    }
    if (values.match_id_team1) {
      defaultValues.match_id_team1 = attributeValuesMatch(
        template.attribute_choices!.match_id_team1,
        values.match_id_team1 as number[]
      );
    }
    if (values.match_id_team2) {
      defaultValues.match_id_team2 = attributeValuesMatch(
        template.attribute_choices!.match_id_team2,
        values.match_id_team2 as number[]
      );
    }
  } else {
    if (report.report_type === 'scout') {
      defaultValues.type = {
        label: 'For player',
        id: 'For player'
      };
    }
  }

  const { control, formState, handleSubmit, watch, getValues, setValue, resetField } =
    useForm<LineChartElementFormValues>({
      defaultValues
    });
  const type = watch('type');
  const timeScale = watch('time_scale');

  const addElement = useCallback(
    function addElement(data: LineChartElementFormValues) {
      const seasons = getValues('seasons');
      const formData = { ...data };

      if (timeScale?.id === 'By season' && seasons.length === 0) {
        formData.seasons = template.attribute_choices!.seasons.map(
          (s: { season: string }) =>
            ({
              label: s.season,
              id: s.season
            }) as ValueOption
        );
      }

      onSubmitForm(formData);
    },
    [getValues, onSubmitForm, template.attribute_choices, timeScale?.id]
  );

  useEffect(() => {
    if (timeScale?.id !== 'By season') {
      setValue('seasons', []);
    }
  }, [setValue, timeScale]);

  useEffect(() => {
    if (type?.id !== 'For specific match') {
      resetField('match_id');
      resetField('match_id_team1');
      resetField('match_id_team2');
      resetField('match_id_player1');
      resetField('match_id_player2');
      resetField('metrics_within_match');
    } else {
      resetField('metrics_within_season');
      resetField('seasons');
      resetField('competitions');
    }
  }, [resetField, type]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      {report.report_type !== 'match' && (
        <SingleSelectInput
          name={'type'}
          control={control}
          label={'Type'}
          disabled={report.report_type === 'scout'}
          options={template.attribute_choices!.type.map(
            (option: string) =>
              ({
                label: snakeCaseToWords(option),
                id: option
              }) as ValueOption
          )}
          rules={{ required: 'Type is required!' }}
          error={formState.errors.type}
        />
      )}
      {type?.id === 'For specific match' && (
        <>
          {(report.report_type === 'player' || report.report_type === 'team') && (
            <SingleSelectInput
              name={'match_id'}
              control={control}
              label={'Choose Match'}
              searchable
              options={template.attribute_choices!.match_id.map(
                (match: Match) =>
                  ({
                    label: `${match.home_team_name} vs ${match.away_team_name} | ${new Date(match.date).toLocaleDateString()}`,
                    id: match.match_id
                  }) as ValueOption
              )}
              rules={{ required: 'Match is required!' }}
              error={formState.errors.match_id}
            />
          )}
          {report.report_type === 'player_comparison' && (
            <>
              <SingleSelectInput
                name={'match_id_player1'}
                control={control}
                label={'Choose Match for Player 1'}
                searchable
                options={template.attribute_choices!.match_id_player1.map(
                  (match: Match) =>
                    ({
                      label: `${match.home_team_name} vs ${match.away_team_name} | ${new Date(match.date).toLocaleDateString()}`,
                      id: match.match_id
                    }) as ValueOption
                )}
                rules={{ required: 'Match is required!' }}
                error={formState.errors.match_id_player1}
              />
              <SingleSelectInput
                name={'match_id_player2'}
                control={control}
                label={'Choose Match for Player 2'}
                searchable
                options={template.attribute_choices!.match_id_player2.map(
                  (match: Match) =>
                    ({
                      label: `${match.home_team_name} vs ${match.away_team_name} | ${new Date(match.date).toLocaleDateString()}`,
                      id: match.match_id
                    }) as ValueOption
                )}
                rules={{ required: 'Match is required!' }}
                error={formState.errors.match_id_player2}
              />
            </>
          )}
          {report.report_type === 'team_comparison' && (
            <>
              <SingleSelectInput
                name={'match_id_team1'}
                control={control}
                label={'Choose Match for Team 1'}
                searchable
                options={template.attribute_choices!.match_id_team1.map(
                  (match: Match) =>
                    ({
                      label: `${match.home_team_name} vs ${match.away_team_name} | ${new Date(match.date).toLocaleDateString()}`,
                      id: match.match_id
                    }) as ValueOption
                )}
                rules={{ required: 'Match is required!' }}
                error={formState.errors.match_id_team1}
              />
              <SingleSelectInput
                name={'match_id_team2'}
                control={control}
                label={'Choose Match for Team 2'}
                searchable
                options={template.attribute_choices!.match_id_team2.map(
                  (match: Match) =>
                    ({
                      label: `${match.home_team_name} vs ${match.away_team_name} | ${new Date(match.date).toLocaleDateString()}`,
                      id: match.match_id
                    }) as ValueOption
                )}
                rules={{ required: 'Match is required!' }}
                error={formState.errors.match_id_team2}
              />
            </>
          )}
        </>
      )}
      {(type?.id === 'For player' || type?.id === 'For team') && report.report_type !== 'scout' && (
        <MultipleSelectInput
          name={'competitions'}
          control={control}
          label={'Choose Competition'}
          options={template.attribute_choices!.competitions.map(
            (competition: Competition) =>
              ({
                label: competition.competition_name,
                id: competition.competition_id
              }) as ValueOption
          )}
          rules={{
            validate: {
              minLength: (values: ValueOption[]) => values?.length > 0 || 'At least one competition is required!'
            }
          }}
          error={formState.errors.competitions}
        />
      )}
      {report.report_type === 'match' && (
        <SingleSelectInput
          name={'metrics_within_match'}
          control={control}
          label="Metrics"
          options={template.attribute_choices!.metrics_within_match.map(
            (option: string) =>
              ({
                label: snakeCaseToWords(option),
                id: option
              }) as ValueOption
          )}
          rules={{ required: 'Metric is required!' }}
          error={formState.errors.metrics_within_match}
        />
      )}
      {report.report_type !== 'match' && (
        <div className="flex gap-6">
          <SingleSelectInput
            name={'time_scale'}
            control={control}
            label={'X - Axis'}
            placeholder="Time scale"
            options={template.attribute_choices!.time_scale.map(
              (option: string) =>
                ({
                  label: snakeCaseToWords(option),
                  id: option
                }) as ValueOption
            )}
            rules={{ required: 'Time scale is required!' }}
            error={formState.errors.time_scale}
          />
          {type?.id === 'For specific match' ? (
            <SingleSelectInput
              name={'metrics_within_match'}
              control={control}
              disabled={!type}
              label={'Y - axis'}
              placeholder="Metric"
              options={template.attribute_choices!.metrics_within_match.map(
                (option: string) =>
                  ({
                    label: snakeCaseToWords(option),
                    id: option
                  }) as ValueOption
              )}
              rules={{ required: 'Metric is required!' }}
              error={formState.errors.metrics_within_match}
            />
          ) : (
            <SingleSelectInput
              name={'metrics_within_season'}
              control={control}
              disabled={!type}
              label={'Y - axis'}
              placeholder="Metric"
              options={template.attribute_choices!.metrics_within_season.map(
                (option: string) =>
                  ({
                    label: snakeCaseToWords(option),
                    id: option
                  }) as ValueOption
              )}
              rules={{ required: 'Metric is required!' }}
              error={formState.errors.metrics_within_season}
            />
          )}
        </div>
      )}
      {timeScale?.id === 'By season' && template.attribute_choices?.seasons && (
        <MultipleSelectInput
          name={'seasons'}
          control={control}
          label={'Seasons'}
          placeholder="All"
          options={template.attribute_choices.seasons.map(
            (s: { season: string }) =>
              ({
                label: s.season,
                id: s.season
              }) as ValueOption
          )}
        />
      )}
    </form>
  );
});

export default LineChartElementForm;
