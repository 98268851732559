/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
  default as getProjectDataTableDataTableIdMutator,
  default as getProjectDataTableMutator
} from '../../api/axios';
import type { DataTableSchema, DataTablesSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all available data_tables.
 */
export const getProjectDataTable = (
  options?: SecondParameter<typeof getProjectDataTableMutator>,
  signal?: AbortSignal
) => {
  return getProjectDataTableMutator<DataTablesSchema>({ url: `/project/data-table`, method: 'GET', signal }, options);
};

export const getGetProjectDataTableQueryKey = () => {
  return [`/project/data-table`] as const;
};

export const getGetProjectDataTableQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectDataTable>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectDataTable>>, TError, TData>>;
  request?: SecondParameter<typeof getProjectDataTableMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectDataTableQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectDataTable>>> = ({ signal }) =>
    getProjectDataTable(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectDataTable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectDataTableQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectDataTable>>>;
export type GetProjectDataTableQueryError = unknown;

/**
 * @summary Returns a list of all available data_tables.
 */
export const useGetProjectDataTable = <
  TData = Awaited<ReturnType<typeof getProjectDataTable>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectDataTable>>, TError, TData>>;
  request?: SecondParameter<typeof getProjectDataTableMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectDataTableQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the data_table object with the given id.
 */
export const getProjectDataTableDataTableId = (
  dataTableId: string,
  options?: SecondParameter<typeof getProjectDataTableDataTableIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectDataTableDataTableIdMutator<DataTableSchema>(
    { url: `/project/data-table/${encodeURIComponent(String(dataTableId))}`, method: 'GET', signal },
    options
  );
};

export const getGetProjectDataTableDataTableIdQueryKey = (dataTableId: string) => {
  return [`/project/data-table/${dataTableId}`] as const;
};

export const getGetProjectDataTableDataTableIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectDataTableDataTableId>>,
  TError = unknown
>(
  dataTableId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectDataTableDataTableId>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectDataTableDataTableIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectDataTableDataTableIdQueryKey(dataTableId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectDataTableDataTableId>>> = ({ signal }) =>
    getProjectDataTableDataTableId(dataTableId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!dataTableId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectDataTableDataTableId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectDataTableDataTableIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectDataTableDataTableId>>
>;
export type GetProjectDataTableDataTableIdQueryError = unknown;

/**
 * @summary Returns the data_table object with the given id.
 */
export const useGetProjectDataTableDataTableId = <
  TData = Awaited<ReturnType<typeof getProjectDataTableDataTableId>>,
  TError = unknown
>(
  dataTableId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectDataTableDataTableId>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectDataTableDataTableIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectDataTableDataTableIdQueryOptions(dataTableId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
