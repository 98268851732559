import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';
import { AttributeValues } from '../../elements/interfaces';
import { attributeValuesOption } from './helpers';
import { ElementFormProps, ElementFormValues } from './interfaces';

interface PositionMapElementFormValues extends ElementFormValues {
  position_type: ValueOption | null;
}

const PositionMapElementForm = memo(function PositionMapElementForm({
  template,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<PositionMapElementFormValues> = {};
  if (element?.attribute_values) {
    const values = element?.attribute_values as AttributeValues<PositionMapElementFormValues>;
    if (values.position_type) {
      defaultValues.position_type = attributeValuesOption(values.position_type as string[]);
    }
  }
  const { control, formState, handleSubmit } = useForm<PositionMapElementFormValues>({ defaultValues });

  const addElement = useCallback(
    function addElement(data: PositionMapElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <SingleSelectInput
        name={'position_type'}
        control={control}
        label={'Position Type'}
        options={template.attribute_choices!.position_type.map(
          (val: string) =>
            ({
              label: snakeCaseToWords(val),
              id: val
            }) as ValueOption
        )}
        rules={{ required: 'Position type is required!' }}
        error={formState.errors.position_type}
      />
    </form>
  );
});

export default PositionMapElementForm;
