import Axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';

const axios = Axios.create({
  baseURL: import.meta.env.VITE_BASE_URL
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      if (!window.location.pathname.startsWith('/login')) {
        window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`;
      }
    }
    return Promise.reject(error);
  }
);

export default async function customInstance<T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> {
  const token = localStorage.getItem('token');
  if (token) config.headers = new AxiosHeaders({ Authorization: 'Bearer ' + token });
  const { data } = await axios({ ...config, ...options });
  return data;
}
