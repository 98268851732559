import { useQueryClient } from '@tanstack/react-query';
import { ProjectSchema } from 'lib/model';
import SelectButton from 'modules/common/SelectButton';
import { memo, useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';
import { ScoutReportFormValues } from '../interfaces';

interface ScoutReportManualFromMetricButtonProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
}

function ScoutReportManualFromMetricButton({ scoutReportForm }: ScoutReportManualFromMetricButtonProps) {
  const metric = useWatch({
    control: scoutReportForm.control,
    name: 'metric'
  })!;

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const playerMetrics = queryClient.getQueryData<string[]>(['playerMetrics', project.id])!;
  const playerMetricOptions = useMemo(
    () => playerMetrics.map((m) => ({ id: m, label: snakeCaseToWords(m) }) as ValueOption) ?? [],
    [playerMetrics]
  );

  const handleMetricChange = useCallback(
    function handleMetricChange(option: ValueOption) {
      scoutReportForm.setValue('metric', option);
    },
    [scoutReportForm]
  );

  return (
    <SelectButton
      loading={false}
      initial={metric}
      options={playerMetricOptions}
      size="md"
      onChange={handleMetricChange}
    />
  );
}

export default memo(ScoutReportManualFromMetricButton);
